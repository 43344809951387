import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TemplateMetadataModel } from "../types/template";

export interface TemplateState {
    loading: boolean;
    loadingError: string;
    templates: TemplateMetadataModel[];
}

const initialState: TemplateState = {
    loading: false,
    loadingError: "",
    templates: []
};

const templateSlice = createSlice({
    name: "template",
    initialState,
    reducers: {
        setTemplateLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        setTemplateLoadingError: (state, action: PayloadAction<string>) => {
            state.loadingError = action.payload;
        },
        populateTemplates: (state, action: PayloadAction<TemplateMetadataModel[]>) => {
            state.templates = [...action.payload];
        },
        addTemplate: (state, action: PayloadAction<TemplateMetadataModel>) => {
            state.templates.push(action.payload);
        }
    }
});

export const selectTemplate = (state: { template: TemplateState }) => state.template.templates;

// Export actions
export const { setTemplateLoading, setTemplateLoadingError, populateTemplates, addTemplate } =
    templateSlice.actions;

export default templateSlice.reducer;
