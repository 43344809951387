import { FormikProps } from "formik";
import FormikFormInputControl from "./tools/FormikFormInputControl";
import { convertToTitleCase } from "../../utils/strings.utils";
import { InitialValuesType } from "./ConfigureSkill";

interface ArgumentConfig {
    type: string;
    required: boolean;
    parameter_type: string;
    description?: string;
    test?: boolean;
    item_type?: string;
}

interface DisplayNode {
    title: string;
    description: string;
    arguments: string[];
}

const ConfigCards: React.FC<{
    node: DisplayNode;
    argumentsObject: Record<string, ArgumentConfig>;
    formik: FormikProps<InitialValuesType>;
    fieldPrefix: string;
}> = ({ node, argumentsObject, formik, fieldPrefix }) => {
    return (
        <div className="w-full mb-6">
            <div className="mb-4">
                <h2 className="text-lg font-semibold">{node.title}</h2>
                <p>{node.description}</p>
            </div>
            <div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border-b mr-4">
                    {node.arguments.map((arg, _index) => {
                        const argConfig = argumentsObject[arg];

                        if (!argConfig) {
                            return null;
                        }

                        const updatedArgConfig = {
                            ...argConfig,
                            key: arg,
                            label: convertToTitleCase(arg)
                        };

                        return (
                            <div key={arg} className="space-y-2">
                                <FormikFormInputControl
                                    key={`formik-control-${_index}`}
                                    name={fieldPrefix + arg}
                                    argument={updatedArgConfig}
                                    formikProps={formik}
                                />
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default ConfigCards;
