import { PersonaModel } from "../models/persona.model";

export function getPersonaFullName(persona: PersonaModel, personaString?: string): string {
    if (persona && persona.first_name) {
        return `${persona.first_name} ${persona.last_name}`;
    } else {
        return personaString || "";
    }
}

export function getPersonaProfilePicture(persona: PersonaModel): string {
    if (persona?.profile_picture_full_2D) {
        const [filename, extension] = persona.profile_picture_full_2D.split(".");
        return `${process.env.REACT_APP_API_URL}/api/v1/persona/show-profile-picture/${filename}/${extension}`;
    } else {
        return "/img/persona_avatar.jpg";
    }
}

export function getProfilePictureFileName(url: string): string | null {
    const regex = /\/api\/v1\/persona\/show-profile-picture\/([a-zA-Z0-9\-]+)\/([a-zA-Z0-9]+)$/;
    const match = url.match(regex);

    if (match) {
        const filename = match[1];
        const extension = match[2];
        return `${filename}.${extension}`;
    }

    return "";
}
