import axios, { AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";
import {
    addUploadedItem,
    clearAllMemories,
    deleteMemoryFile,
    deleteMemoryLocation,
    deleteMemorySector,
    setLocations,
    setMemoriesData,
    setMemoriesTree,
    setNamespace,
    setNameSpaceIsError,
    setNameSpaceLoadingState,
    setSectors,
    setUploadedItems,
    updateMemoryFileName,
    updateMemoryLocationName,
    updateMemorySectorName,
    UploadDataItem
} from "../store/slices/memories.slice";
import { capitalizeWord, removeDuplicates } from "./strings.utils";
import { useDispatch, useSelector } from "react-redux";
import {
    addActiveFolderQuery,
    addFolder,
    populateActiveFolderFiles,
    populateFolders,
    removeLastActiveFolderQuery,
    setActiveFolder,
    setFolderLoading,
    updateActiveFolderQuery,
    updateLastActiveFolderQuery
} from "../store/slices/folder.slice";
import { CharacterModel } from "../models/character.model";
import {
    removeLastQuery,
    setCharacter,
    setCharacterLoadingError,
    setCharacterLoadingStatus,
    setFolderHistory,
    setHasPromptsLoaded,
    setHistory,
    setPrompts,
    setSearchLoadingStatus,
    updateLastQuery,
    updateSearchQuery
} from "../store/slices/searches.slice";
import {
    addThreadQuery,
    removeLastThreadQuery,
    removeThreadQuery,
    setThreadHistory,
    setThreadLoadingStatus,
    updateLastThreadQuery,
    updateThreadQuery
} from "../store/slices/threads.slice";
import { SearchModel, ThreadModel } from "../models/search.model";
import { MemoriesTreeModel, MemoryBrainType } from "../models/memories.model";
import {
    AccountApiKeys,
    AccountState,
    clearSubscriptionData,
    OrganizationState,
    setAccountApiKeys,
    setAccountData,
    setAccountOrganizations,
    setAccountSubscriptionData,
    setActiveOrganization,
    setOrganizations,
    setRetailUser,
    setSubscriptionStatus,
    setUserAccountExists,
    setUserAccountLoading,
    setUserRole,
    setVendorProfile
} from "../store/slices/account.slice";
import { populateUserQueries, updateUserQuery } from "../store/slices/user-queries.slice";
import { newUTCDate } from "./date.utils";
import {
    addAutoInteractionMessage,
    removeCollaborationGroupApp,
    setAutoInteractionId,
    setCollaborateInitializationMessage,
    setCollaborationAppGroups,
    setCollaborationAppId,
    setCollaborationPersona,
    setIsAutoInteractionInitialized,
    setIsAutoInteractionInitializing,
    removeCollaborationGroup,
    setSocketRoom,
    addCollaborationGroupApp,
    setCollaborateViewMode,
    setIsCollaborateLoading,
    setEnableCollaborationInput,
    clearAutoInteractionMessages,
    clearBrainActivityMessages,
    setSelectCollaborationApp,
    setIsExecuting,
    setIsExecuted,
    prependCollaborationGroup,
    setIsCreatingCollaborateApp,
    setIsCollabAppLoading,
    setCollabGroupPage,
    appendCollaborationGroup
} from "../store/slices/collaborate.slice";
import {
    addPersona,
    populatePersonas,
    removePersona,
    setPersonalityTraitQuestions,
    setPersonaLoading,
    setPersonaLoadingError,
    setSelectedPersonaMemories,
    updatePersona,
    setPersonaPage,
    appendPersonas
} from "../store/slices/persona.slice";
import { PersonaCollaborationAppGroupModel, PersonaModel } from "../models/persona.model";
import {
    addChatQuery,
    removeLastChatQuery,
    removeTypingQuery,
    setChatLoadingStatus,
    updateLastChatQuery
} from "../store/slices/chat.slice";
import { sortListByProperty } from "./array.utils";
import {
    addPaymentMethodToState,
    deletePaymentMethodFromState,
    setPaymentDefaultPaymentMethod,
    setPaymentMethods
} from "../store/slices/payment-method.slice";
import hotToast from "react-hot-toast";
import { OrganizationModel } from "../models/organization.model";
import KeycloakClient from "../providers/auth/keycloak-client";
import { getProfilePictureFileName } from "./personas.utils";
import { InitialValuesType } from "../components/global/ConfigureSkill";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

const authClient = axios.create({
    baseURL: process.env.REACT_APP_AUTH_URL
});

const userApiGatewayClient = axios.create({
    baseURL: process.env.REACT_APP_USER_API_GATEWAY_URL
});

// set auth headers
authClient.defaults.headers.common["Content-Type"] = "application/json";
authClient.defaults.headers.common["Client-Id"] = process.env.REACT_APP_CLIENT_ID;
authClient.defaults.headers.common["Client-Secret"] = process.env.REACT_APP_CLIENT_SECRET;

// const lootNFTClient = axios.create({
//     baseURL: process.env.REACT_APP_LOOTNFT_PUBLIC_API,
// });

interface FetchParams {
    search_criteria?: any;
    page?: number;
    limit?: number;
    sort?: any;
    case_insensitive?: boolean;
}

export function useHttpClient() {
    const toast = useToast();
    const dispatch = useDispatch();
    const apiKeys: AccountApiKeys = useSelector((state: any) => state.account.apiKeys);
    const { accountData, activeOrganization }: AccountState = useSelector(
        (state: any) => state.account
    );

    function addAPICredentialsToPayload(payload: any) {
        return {
            ...payload,
            OPENAI_KEY: apiKeys.openAi.apiKey,
            Pinecone_KEY: apiKeys.pinecone.key,
            Pinecone_ENV: apiKeys.pinecone.env,
            Pinecone_Index: apiKeys.pinecone.index
        };
    }

    // TODO: Add a strategy to fetch token from session
    // HttpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    async function fetchSearchByIdApi(id: string) {
        return httpClient.get(`/search/searches/${id}`);
    }

    async function fetchSearchesApi() {
        return httpClient
            .get(`/search/searches`)
            .then(({ data }) => {
                dispatch(populateUserQueries(data));
            })
            .catch((err) => {
                toast({
                    title: "Cannot load searches",
                    status: "error"
                });
            });
    }

    async function fetchSearchReplyApi(searchPayload: any) {
        try {
            /**
             *  Send search Query
             *  "O" is public
             *  "L" is private
             */

            // if (searchPayload && searchPayload.check_list && Array.isArray(searchPayload.check_list)) {
            //     searchPayload.check_list = searchPayload.check_list.join(",");
            //     searchPromise = httpClient.post(`/search/rlhf-search`, addAPICredentialsToPayload(searchPayload))
            // } else if (searchPayload.check_list.toLowerCase() === "thoughts") {
            //     searchPromise = httpClient.post(`/api/v1/note/get-note-results`, addAPICredentialsToPayload(searchPayload));
            // } else {
            //     searchPromise = httpClient.post(`/search/rlhf-search`, addAPICredentialsToPayload(searchPayload))
            // }

            const { data } = await httpClient.post(`/search/searches`, {
                ...searchPayload,
                relation: ""
            });

            // const {data} = await searchPromise;

            // update current
            dispatch(
                updateLastQuery({
                    ...data,
                    isFinishedTyping: false,
                    isLoading: false,
                    isExpanded: false
                })
            );

            // change loading status
            dispatch(setSearchLoadingStatus(false));
        } catch (e) {
            dispatch(removeLastQuery());
            toast({
                title: "We had trouble asking AI Character",
                description: "If this error persist, please contact the system administrators",
                status: "error",
                duration: 2000
            });

            // change loading status
            dispatch(setSearchLoadingStatus(false));
        }
    }

    async function fetchNotesReplyApi(searchPayload: any) {
        // /api/v1/note/add-note-summary
        return await httpClient
            .post(`/api/v1/note/add-note-summary`, searchPayload)
            .then(({ data }) => {
                if (data["success"] === false) {
                    throw new Error("failed");
                }
                // console.log(data)
                // update current
                dispatch(
                    updateLastQuery({
                        question: "Thoughts",
                        reply: data["note_summary_response"].message,
                        isFinishedTyping: false,
                        isLoading: false,
                        isExpanded: false
                    })
                );
            })
            .catch((e) => {
                console.error(e);
                dispatch(removeLastQuery());
                toast({
                    title: "We had trouble asking AI Character",
                    description: "If this error persist, please contact the system administrators",
                    status: "error",
                    duration: 2000
                });
            })
            .finally(() => {
                // change loading status
                dispatch(setSearchLoadingStatus(false));
            });
    }

    async function fetchNotesReplyInFolderApi(folderName: string, searchPayload: any) {
        try {
            // add active folder query to show loading state on screen
            dispatch(
                addActiveFolderQuery({
                    isFinishedTyping: false,
                    isLoading: true,
                    question: "Thoughts",
                    type: "notes",
                    date_added: newUTCDate(),
                    character: searchPayload.character,
                    character_message: searchPayload.character_message,
                    reply: ""
                })
            );
            // Fetching search data
            const { data } = await httpClient.post(
                `/api/v1/note/add-note-summary`,
                addAPICredentialsToPayload(searchPayload)
            );
            // Save reply to folder
            // TODO: SAVE FOLDERS TO NOTES
            // await saveToFolderApi(data.id, folderName);
            // add to
            dispatch(
                updateLastActiveFolderQuery({
                    ...data,
                    isFinishedTyping: false,
                    isLoading: false,
                    isExpanded: false
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(removeLastActiveFolderQuery());
            toast({
                title: "We had trouble asking AI Character",
                description: "If this error persist, please contact the system administrators",
                status: "error",
                duration: 2000
            });
        }
    }

    async function fetchNotesInThreadApi(searchPayload: any) {
        try {
            // set loading status...
            dispatch(setThreadLoadingStatus(true));
            // add active folder query to show loading state on screen
            dispatch(
                addThreadQuery({
                    isFinishedTyping: false,
                    isLoading: true,
                    question: "Thoughts",
                    type: "notes",
                    date_added: newUTCDate(),
                    character: searchPayload.character,
                    character_message: searchPayload.character_message,
                    reply: ""
                })
            );
            // Fetching search data
            const { data } = await httpClient.post(
                `/api/v1/note/add-note-summary`,
                addAPICredentialsToPayload(searchPayload)
            );
            // add to
            dispatch(
                updateLastThreadQuery({
                    ...data,
                    isFinishedTyping: false,
                    isLoading: false,
                    isExpanded: false
                })
            );

            // set loading status...
            dispatch(setThreadLoadingStatus(false));
        } catch (e) {
            console.error(e);
            dispatch(removeLastThreadQuery());
            toast({
                title: "We had trouble asking AI Character",
                description: "If this error persist, please contact the system administrators",
                status: "error",
                duration: 2000
            });

            // set loading status...
            dispatch(setThreadLoadingStatus(false));
        }
    }

    async function fetchSearchReplyInFolderApi(folderName: string, searchPayload: any) {
        try {
            // Fetching search data
            const { data } = await httpClient.post(`/search/searches`, searchPayload);
            // Save reply to folder
            await saveToFolderApi(data.id, folderName);
            // add to
            dispatch(
                updateLastActiveFolderQuery({
                    ...data,
                    isFinishedTyping: false,
                    isLoading: false,
                    isExpanded: false
                })
            );
        } catch (e) {
            console.error(e);
            dispatch(removeLastActiveFolderQuery());
            toast({
                title: "We had trouble asking AI Character",
                description: "If this error persist, please contact the system administrators",
                status: "error",
                duration: 2000
            });
        }
    }

    async function fetchThreadReplyApi(searchPayload: any) {
        /**
         *  Send search Query
         */

        return httpClient
            .post(`/thread/thread_search`, searchPayload)
            .then(({ data }) => {
                // update current
                dispatch(
                    updateLastThreadQuery({
                        ...data,
                        isFinishedTyping: false,
                        isLoading: false,
                        isExpanded: false
                    })
                );
            })
            .catch(() => {
                dispatch(removeLastThreadQuery());
                toast({
                    title: "We had trouble asking AI Character",
                    description: "If this error persist, please contact the system administrators",
                    status: "error",
                    duration: 2000
                });
            })
            .finally(() => {
                // change loading status
                dispatch(setThreadLoadingStatus(false));
            });
    }

    /**
     * Download file from server
     *
     */
    async function downloadFileApi(fileName: string) {
        try {
            // Show downloading notification
            toast({
                title: "File Download Started..",
                duration: 3500
            });
            // send request
            const { data } = await httpClient.post(
                `/api/v1/query-file/download-file`,
                { fileName },
                { responseType: "blob" }
            );
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", fileName);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error("Error downloading file:", error);
        }
    }

    // Get character
    async function fetchCharacterApi() {
        // reset character loading error..
        dispatch(setCharacterLoadingStatus(true));
        dispatch(setCharacterLoadingError(false));
        // Fetch from api
        httpClient
            .post<CharacterModel>(`/search/Character`)
            .then(({ data }) => {
                dispatch(setCharacter(data));
                // save character data to session to avoid accidental browser refresh
                // window.sessionStorage.setItem('character', JSON.stringify(data));
            })
            .catch((error) => {
                // console.error(error);
                dispatch(setCharacterLoadingError(true));
                // toast({
                //     title: "Unable to connect to server",
                //     description: "We are unable to connect to the server, Please try again later",
                //     duration: 4500,
                //     status: "error"
                // });
            })
            .finally(() => {
                dispatch(setCharacterLoadingStatus(false));
            });
    }

    async function fetchNameSpacesApi() {
        try {
            dispatch(setNameSpaceLoadingState(true));
            dispatch(setNameSpaceIsError(false));

            const namespaceResponse = await httpClient.get(`/data-upload/namespaces`);
            let sectors: string[] = [],
                locations: string[] = [];
            let uploadedItems: UploadDataItem[] = [];

            let namespaces = namespaceResponse.data.map((n: any) => {
                const [_, sector, location] = n.namespace.split("-axbyzc-");
                const file = n.filename;
                sectors.push(capitalizeWord(sector));
                locations.push(capitalizeWord(location));

                if (file) {
                    const lastIndex = file.lastIndexOf("."),
                        extension = file.substring(lastIndex + 1);

                    uploadedItems.push({
                        sector: capitalizeWord(sector),
                        location: capitalizeWord(location),
                        path: file || "",
                        file: {
                            name: file || "",
                            isStructured: ["xls", "xlsx", "csv"].includes(extension),
                            type: extension
                        }
                    });
                }

                return {
                    ...n,
                    sector: capitalizeWord(sector),
                    location: capitalizeWord(location)
                };
            });
            // Remove duplicates
            sectors = removeDuplicates(sectors);
            locations = removeDuplicates(locations);

            dispatch(setNamespace(namespaces));
            dispatch(setSectors(sectors));
            dispatch(setLocations(locations));
            // console.log(uploadedItems)
            // update namespace data...
            dispatch(setNameSpaceLoadingState(false));
            dispatch(setNameSpaceLoadingState(false));
            dispatch(setUploadedItems(uploadedItems));
        } catch (e) {
            console.error(e);
            dispatch(setNameSpaceIsError(true));
            dispatch(setNameSpaceLoadingState(false));
        }
    }

    async function fetchNameSpacesV2Api() {}

    async function fetchMemoriesApi() {
        try {
            dispatch(setNameSpaceLoadingState(true));
            dispatch(setNameSpaceIsError(false));
            // namespace selection: /search/namespace-selection
            const { data } = await httpClient.post(`/api/v1/data-upload/list-data-upload`);
            let memoriesTree: MemoriesTreeModel[] = [];

            // console.log(data)

            if (data.length) {
                // it's okay
                for (const memory of data) {
                    const sectorIndex = memoriesTree.findIndex(
                        (sectorItem) =>
                            sectorItem.sector_id === memory.data_sector &&
                            sectorItem.brain_type === memory.brain_type
                    );

                    if (sectorIndex > -1) {
                        // find location name
                        const locationIndex = memoriesTree[sectorIndex].locations.findIndex(
                            (locationItem: any) => locationItem.location_id === memory.data_location
                        );

                        if (locationIndex > -1) {
                            memoriesTree[sectorIndex].locations[locationIndex].files.push({
                                upload_id: memory.id,
                                name: memory.filename_display,
                                brain_type: memory.brain_type
                            });
                        } else {
                            memoriesTree[sectorIndex].locations.push({
                                location_id: memory.data_location,
                                location: memory.location,
                                // namespace: `${email}-axbyzc-${memory.sector}-axbyzc-${memory.location}-axbyzc-${memory.filename}`,
                                namespace: memory.namespace,
                                location_display: memory.location_display,
                                location_description: memory.location_description,
                                files: [
                                    {
                                        upload_id: memory.id,
                                        name: memory.filename_display,
                                        brain_type: memory.brain_type
                                    }
                                ]
                            });
                        }
                    } else {
                        memoriesTree.push({
                            id: memory.id,
                            sector_id: memory.data_sector,
                            // namespace: `${email}-axbyzc-${memory.sector}-axbyzc-${memory.location}-axbyzc-${memory.filename}`,
                            namespace: memory.namespace,
                            sector: memory.sector,
                            brain_type: memory.brain_type,
                            is_structured: memory.is_structured,
                            relation: memory.relation,
                            sector_display: memory.sector_display,
                            sector_description: memory.sector_description,
                            locations: [
                                {
                                    location_id: memory.data_location,
                                    location: memory.location,
                                    namespace: memory.namespace,
                                    // namespace: `${email}-axbyzc-${memory.sector}-axbyzc-${memory.location}-axbyzc-${memory.filename}`,
                                    location_display: memory.location_display,
                                    location_description: memory.location_description,
                                    files: [
                                        {
                                            upload_id: memory.id,
                                            name: memory.filename_display,
                                            brain_type: memory.brain_type
                                        }
                                    ]
                                }
                            ]
                        });
                    }
                }
            }

            memoriesTree = sortListByProperty(memoriesTree, "sector_display");

            // Save memories data
            dispatch(setMemoriesData(data));
            // Save memories tree
            dispatch(setMemoriesTree(memoriesTree));
            // update namespace data...
            dispatch(setNameSpaceLoadingState(false));
            dispatch(setNameSpaceLoadingState(false));
        } catch (e) {
            console.error(e);
            dispatch(setNameSpaceIsError(true));
            dispatch(setNameSpaceLoadingState(false));
        }
    }

    async function getAgentNamespace() {
        return httpClient.post(`/search/namespace-selection`, addAPICredentialsToPayload({}));
    }

    async function deleteAllMemoriesApi() {
        try {
            await httpClient.post(`/data-upload/delete-all-namespace`);

            dispatch(clearAllMemories());
        } catch (e) {
            toast({
                title: "Cannot delete all memories",
                description:
                    "We are unable to delete your memories at this time. Please try again later",
                status: "error"
            });
        }
    }

    async function updateSectorApi(sectorId: string, oldName: string, newName: string) {
        try {
            await httpClient.post(`/api/v1/data-upload/update-sector`, {
                data: {
                    data_sector_id: sectorId,
                    sector_name: newName
                }
            });
        } catch (e) {
            toast({
                title: "Cannot update sector name",
                status: "error"
            });
            // Revert back to old sector name
            dispatch(
                updateMemorySectorName({
                    id: sectorId,
                    name: oldName
                })
            );
        }
    }

    async function updateLocationApi(
        sectorId: string,
        locationId: string,
        oldName: string,
        newName: string
    ) {
        try {
            await httpClient.post(`/api/v1/data-upload/update-location`, {
                data: {
                    data_location_id: locationId,
                    location_name: newName
                }
            });
        } catch (e) {
            toast({
                title: "Cannot update location name",
                status: "error"
            });
            // Revert back to old location name
            dispatch(
                updateMemoryLocationName({
                    id: locationId,
                    sector_id: sectorId,
                    name: oldName
                })
            );
        }
    }

    async function deleteSectorApi(
        sectorId: string,
        data: { sectorId: string; sector: string; brainType: MemoryBrainType }
    ) {
        try {
            await httpClient.post(
                `/data-upload/delete-namespace-by-sector`,
                addAPICredentialsToPayload({
                    sector_id: sectorId,
                    sector: data.sector,
                    brain_type: data.brainType
                })
            );

            dispatch(deleteMemorySector({ id: sectorId }));
        } catch (e) {
            console.error(e);
            toast({
                title: "Cannot delete sector",
                status: "error"
            });
            // Revert back to old sector name
        }
    }

    async function deleteLocationApi(
        sectorId: string,
        locationId: string,
        sector: string,
        location: string,
        brainType: MemoryBrainType
    ) {
        try {
            await httpClient.post(
                `/data-upload/delete-namespace-by-location`,
                addAPICredentialsToPayload({
                    sector,
                    location,
                    location_id: locationId,
                    brain_type: brainType
                })
            );

            dispatch(
                deleteMemoryLocation({
                    sectorId,
                    locationId
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Cannot delete location",
                status: "error"
            });
        }
    }

    async function updateSectorFileNameApi(payload: {
        id: string;
        sectorId: string;
        locationId: string;
        oldFileName: string;
        newFileName: string;
    }) {
        try {
            await httpClient.post(`/api/v1/data-upload/update-filename`, {
                data: {
                    data_upload_id: payload.id,
                    filename: payload.newFileName
                }
            });

            dispatch(
                updateMemoryFileName({
                    sectorId: payload.sectorId,
                    locationId: payload.locationId,
                    oldName: payload.oldFileName,
                    newName: payload.newFileName
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Cannot delete file",
                status: "error"
            });
            // Reverse update
            dispatch(
                updateMemoryFileName({
                    sectorId: payload.sectorId,
                    locationId: payload.locationId,
                    oldName: payload.newFileName,
                    newName: payload.oldFileName
                })
            );
        }
    }

    async function deleteSectorFileNameApi(payload: {
        id: string;
        sector: string;
        location: string;
        sectorId: string;
        locationId: string;
        filename: string;
        brainType: string;
    }) {
        try {
            await httpClient.post(
                `/data-upload/delete-namespace-by-filename`,
                addAPICredentialsToPayload({
                    file_id: payload.id,
                    sector: payload.sector,
                    location: payload.location,
                    filename: payload.filename,
                    brain_type: payload.brainType
                })
            );
            // Todo continue

            dispatch(
                deleteMemoryFile({
                    sectorId: payload.sectorId,
                    locationId: payload.locationId,
                    fileName: payload.filename
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Cannot delete file",
                status: "error"
            });
        }
    }

    /**
     *  ========  FOLDERS API ===============
     */
    async function fetchFoldersApi() {
        try {
            // If folders are in state, don't fetch
            const folderResponse = await httpClient.get(`/search/folders`);
            dispatch(populateFolders(folderResponse.data));
        } catch (e) {
            hotToast.error("Cannot fetch your folders at this time, Please try again later");
        }
    }

    async function fetchFolderByName(name: string) {
        try {
            // Set folder loading state to true
            dispatch(setFolderLoading(true));
            // Fetch folder search queries
            const { data } = await httpClient.get(`/search/folder/${name}`);
            // disable typing & populating results
            dispatch(
                populateActiveFolderFiles(
                    data.map((file: SearchModel) => ({
                        ...file,
                        isFinishedTyping: true
                    }))
                )
            );
            // set active folder name
            dispatch(setActiveFolder(name));
            // const activeFolderIds = data.map((search: any) => search.id.toString());
            // const payload = activeFolderIds.join(",");
            // const res = await httpClient.post(`/search/history`, { search_id: payload });
            // set the history in state
            // dispatch(setActiveFolderHistory(res.data.history));
            // set loading to false
            dispatch(setFolderLoading(false));
        } catch (e) {
            console.error(e);
        }
    }

    async function fetchHistoryApi(searchIds: string[], type?: "find" | "folder") {
        try {
            const historyType = type || "find";
            const payload = searchIds.join(",");
            const { data } = await httpClient.post(
                `/search/history`,
                addAPICredentialsToPayload({ search_id: payload })
            );
            // set the history in state
            switch (historyType) {
                case "find":
                    dispatch(setHistory(data.history));
                    break;
                case "folder":
                    dispatch(setFolderHistory(data.history));
                    break;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function fetchThreadHistoryApi(searchIds: string[]) {
        if (searchIds.length < 1) {
            throw new Error("Please provide a search id");
        }
        try {
            let searchId = searchIds.join(",");
            const { data } = await httpClient.post(
                `/search/history`,
                addAPICredentialsToPayload({ search_id: searchId })
            );
            // set the history in state
            dispatch(setThreadHistory(data.history));
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * ========= Suggest edits =============
     */
    /**
     *  Change response sentiment for a search
     * @param response
     * @param sentiment
     */
    async function changeResponseSentimentApi(response: SearchModel, sentiment: string) {
        // Update search card in state before fetch, so we show the user an appropriate feedback.
        let oldSentiment = response.Sentiment,
            sentimentPromise: Promise<AxiosResponse>;
        dispatch(updateSearchQuery({ ...response, Sentiment: sentiment }));
        // update it in folder too
        dispatch(updateActiveFolderQuery({ ...response, Sentiment: sentiment }));
        // If response is RHLF data, use penalty to update sentiment
        if (response["RLHF_id"]) {
            sentimentPromise = httpClient.post(
                `/search/rlhf-penalty/`,
                addAPICredentialsToPayload({ sentiment, id: response.id })
            );
        } else {
            sentimentPromise = httpClient.post(`/search/sentiment/${response.id}`, { sentiment });
        }
        // Call api
        sentimentPromise
            .then(() => {
                oldSentiment = "";
            })
            .catch((error) => {
                // Update search card in state...
                dispatch(updateSearchQuery({ ...response, Sentiment: oldSentiment }));
                // update it in folder too
                dispatch(
                    updateActiveFolderQuery({
                        ...response,
                        Sentiment: oldSentiment
                    })
                );

                toast({
                    title: "Unable to update sentiment for this search",
                    description: "We are unable to update at this time, Please try again later",
                    duration: 1200
                });
            });
    }

    async function approveSearchForTrainingApi(search: SearchModel, approval: string) {
        /**
         * First update
         */
        dispatch(updateUserQuery({ ...search, is_approved: approval === "1" }));
        const { data } = await httpClient.post(`/search/encryption-key`);
        // searchPayload['encryption_key'] = data.encryption_key;
        return httpClient
            .put(
                `/search/approved/${search.id}`,
                addAPICredentialsToPayload({
                    approved: approval,
                    ency_str_key: data.data
                })
            )
            .then(() => {
                if (approval === "1") {
                    toast({
                        title: "Approved for training",
                        status: "success",
                        duration: 1500
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                // If the update fails, revert back to initial value
                dispatch(
                    updateUserQuery({
                        ...search,
                        approved: search.is_approved
                    })
                );
                // Show toast
                toast({
                    title: "Unable to change the approved status of this query ",
                    status: "error",
                    description:
                        "We encountered an error changing the status for this query, Please try again later",
                    duration: 2100
                });
            });
    }

    /**
     *  Change sentiment of thread reply
     *  @param searchId
     * @param response
     * @param sentiment
     */
    async function changeThreadSentimentApi(response: ThreadModel, sentiment: string) {
        // Update search card in state before fetch, so we show the user an appropriate feedback.
        let oldSentiment = response.Sentiment,
            sentimentPromise: Promise<AxiosResponse>;
        // if sentiment was zero, remove from view
        if (sentiment === "0") {
            dispatch(removeThreadQuery(response));
        } else {
            // else update the thread
            dispatch(updateThreadQuery({ ...response, Sentiment: sentiment }));
        }

        if (response["RLHF_id"]) {
            sentimentPromise = httpClient.post(
                `/thread/rlhf-penalty`,
                addAPICredentialsToPayload({
                    sentiment,
                    search_id: response.search_id,
                    thread_id: response.thread_id
                })
            );
        } else {
            sentimentPromise = httpClient.post(
                `/thread/sentiment/${response.search_id}/${response.thread_id}`,
                { sentiment }
            );
        }
        // Call api
        sentimentPromise
            .then(() => {
                oldSentiment = "";
            })
            .catch((error) => {
                // is sentiment was zero, add old thread back to view
                if (sentiment === "0") {
                    dispatch(addThreadQuery(response));
                } else {
                    // Update search card in state...
                    dispatch(
                        updateThreadQuery({
                            ...response,
                            Sentiment: oldSentiment
                        })
                    );
                }

                toast({
                    title: "Unable to update sentiment for this search",
                    description: "We are unable to update at this time, Please try again later",
                    duration: 1200
                });
            });
    }

    /**
     * Suggest edits
     */
    async function editSearchResponseApi(response: SearchModel, suggestedAnswer: string) {
        return httpClient
            .post(
                `/search/suggest/${response.id}`,
                addAPICredentialsToPayload({
                    reply: suggestedAnswer
                })
            )
            .then(() => {
                // update reply
                dispatch(updateSearchQuery({ ...response, reply: suggestedAnswer }));
                // update user queries
                dispatch(updateUserQuery({ ...response, reply: suggestedAnswer }));
                // update folder response if response exist in folder
                dispatch(updateActiveFolderQuery({ ...response, reply: suggestedAnswer }));

                toast({
                    title: "Your updates were saved",
                    duration: 2000
                });
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: "Unable to submit edited answer",
                    description: "Please check your network and try again",
                    duration: 4500
                });
            });
    }

    async function editThreadResponseApi(thread: ThreadModel, suggestedAnswer: string) {
        return httpClient
            .post(`/thread/suggest/${thread.search_id}/${thread.thread_id}`, {
                reply: suggestedAnswer
            })
            .then(() => {
                // update reply
                dispatch(updateThreadQuery({ ...thread, reply: suggestedAnswer }));
                toast({
                    title: "Your updates were saved",
                    duration: 2000
                });
            })
            .catch((err) => {
                console.error(err);
                toast({
                    title: "Unable to submit edited answer",
                    description: "Please check your network and try again",
                    duration: 4500
                });
            });
    }

    /**
     *  =================  SAVING TO FOLDER ================
     */
    async function saveToFolderApi(searchId: number, folderName: string) {
        try {
            await httpClient.put(`/search/folder/${searchId}`, {
                folderName
            });
            // Add folder to current state, in the case of creating a folder
            // The state action will handle filtering duplicates
            dispatch(addFolder({ name: folderName }));
        } catch (e) {
            console.error(e);
            toast({
                title: "There was an error saving your query, Please try again later",
                status: "error",
                duration: 2500
            });
        }
    }

    /**
     *  =================  UPLOAD ================
     */
    function getStructuredDataRelationshipsApi(fileName: string): Promise<AxiosResponse> {
        return httpClient.post(`/data-upload/relationships&fileName=${fileName}`, { fileName });
    }

    async function uploadMemoryFileApi(payload: any) {
        const { data } = await httpClient.post(`/data-upload/upload`, {
            ...payload,
            file: null
        });

        dispatch(
            addUploadedItem({
                sector: payload.sector,
                location: payload.location,
                path: payload.filename,
                file: payload.file,
                brain_type: payload.brain_type
            })
        );

        /**
         * Add sector and memories to tree
         */
        // TODO: REQUIRES OPTIMIZATION
        fetchMemoriesApi();
        // dispatch(addMemoryToTree({
        //     id: data.id,  // TODO: CHECK THIS
        //     sector: payload.sector,
        //     location: payload.location,
        //     file_name: payload.filename_display,
        //     brain_type: payload.brain_type
        // }));

        return data;
    }

    async function uploadMemoryURLApi(payload: any) {
        const { data } = await httpClient.post(`/data-upload/upload-url`, payload);

        // add data to redux state
        dispatch(
            addUploadedItem({
                sector: payload.sector,
                location: payload.location,
                path: payload.filename,
                file: payload.filename
            })
        );

        // dispatch(addMemoryToTree({
        //     id: data.id,  // TODO: CHECK THIS
        //     sector: payload.sector,
        //     location: payload.location,
        //     file_name: payload.filename,
        //     brain_type: payload.brain_type
        // }));
        // TODO: REQUIRES OPTIMIZATION
        fetchMemoriesApi();

        return data;
    }

    async function updateUploadedFileDescriptionApi(payload) {
        return httpClient.post(`/data-upload/update-file-description`, payload);
    }

    /***
     * Save user's info in the database...
     */
    async function createUserApi(payload) {
        return httpClient.post(`/api/v1/user/create-user`, {
            email: payload.email,
            openai_settings: {
                api_key: payload.openAiKey
            },
            pinecone_settings: {
                api_key: payload.pineconeKey,
                env: payload.pineconeIndex,
                index: payload.pineconeIndex
            }
        });
    }

    function listUsersApi(search: any, params?: any) {
        return httpClient.post(`/api/v1/user/list-user`, {
            search_criteria: search,
            pagination: {
                rows_per_page: params?.limit || 50,
                page_number: params?.page || 1
            },
            sort: params?.sort || { createdAt: 1 },
            ...(params.is_vendor !== undefined && { is_vendor: params.is_vendor })
        });
    }

    async function updateUserApi(payload) {
        return httpClient.post(`/api/v1/user/update-user`, {
            search_criteria: { email: payload.email },
            update_params: {
                openai_settings: {
                    api_key: payload.openAiKey
                },
                pinecone_settings: {
                    api_key: payload.pineconeKey,
                    env: payload.pineconeEnv,
                    index: payload.pineconeIndex
                }
            }
        });
    }

    // /**
    //  *  ================ FETCH USER CREDENTIALS =========
    //  */
    // async function getAuthUserDataApi(authToken?: string) {
    //     try {
    //         const { data } = await authClient.get(`/auth/user`, {
    //             headers: {
    //                 Authorization: `Bearer ${authToken || token}`
    //             }
    //         });
    //         return data.authUser;
    //     } catch (e: any) {
    //         // if axios error status is 401, return null
    //         if (e.response?.status === 401) {
    //             return null;
    //         }
    //         // This is a temporal fix, if the 401 status code, log them out
    //         return {};
    //     }
    // }

    async function fetchUserCredentialsApi(userEmail) {
        userEmail = userEmail ?? (await KeycloakClient.getInstance().getAuthProfile())?.email;
        dispatch(setUserAccountLoading(true));

        /**
         * If the user token is expired or invalid
         */
        // get user auth data if it fails redirect the user to the login
        // const authData = await getAuthUserDataApi(authToken);
        const authData = await KeycloakClient.getInstance().getAuthProfile();

        if (!authData) {
            // logOut();
            return;
        }

        const activeOrg = window.sessionStorage.getItem("active_org");
        if (activeOrg) {
            const { data } = await listUsersApi(
                { id: activeOrg },
                { page: 1, limit: 1, is_vendor: true }
            );

            if (data?.[0]) {
                const vendor = data[0];

                dispatch(setUserAccountExists(true));

                dispatch(
                    setAccountApiKeys({
                        openAi: {
                            apiKey: vendor.openai_settings?.api_key
                        },
                        pinecone: {
                            env: vendor.pinecone_settings?.env,
                            index: vendor.pinecone_settings?.index,
                            key: vendor.pinecone_settings?.api_key
                        }
                    })
                );

                // Set account data
                delete vendor.pinecone_settings;
                delete vendor.openai_settings;
                // add to state
                dispatch(setAccountData(vendor));
            }
        }

        const { data } = await listUsersApi({ email: userEmail }, { page: 1, limit: 1 });

        const retailUser = data.find((user) => user.email === userEmail);

        if (retailUser) {
            // Set account data
            delete retailUser.pinecone_settings;
            delete retailUser.openai_settings;
            // add to state
            dispatch(setRetailUser(retailUser));
        }
        dispatch(setUserAccountLoading(false));
    }

    /**
     *  ======== PROMPTS SECTION ===========
     *
     */
    async function fetchPromptCategoriesApi(
        query: any,
        params: FetchParams = {}
    ): Promise<AxiosResponse<any>> {
        return await httpClient.post(`/api/v1/prompt-category/list-prompt-category`, {
            data: {
                search_criteria: query,
                pagination: {
                    rows_per_page: params?.limit || 50,
                    page_number: params?.page || 1
                },
                sort: params?.sort || { createdAt: 1 }
            }
        });
    }

    async function fetchPromptsApi(search: any, params: FetchParams = {}): Promise<any> {
        const { data } = await httpClient.post(`/api/v1/prompt/list-prompt`, {
            data: {
                search_criteria: search,
                pagination: {
                    rows_per_page: params?.limit || 50,
                    page_number: params?.page || 1
                },
                sort: params?.sort || { createdAt: 1 }
            }
        });
        dispatch(setPrompts(data));

        dispatch(setHasPromptsLoaded(true));
        return true;
    }

    /**
     *
     * =============== COLLABORATE SECTION ================
     */

    async function initializeCollaborationCommunicationApi(persona: PersonaModel) {
        try {
            dispatch(setIsAutoInteractionInitializing(true));
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app/initialize-communication`,
                {
                    persona: persona.id
                }
            );

            if (data.success === false) {
                throw new Error("Cannot init");
            }
            // console.log(data);
            dispatch(setSocketRoom(data.socket));
            dispatch(setIsAutoInteractionInitialized(true));
            dispatch(setCollaborationAppId(data["collaboration_app"].id));
            dispatch(setCollaborateInitializationMessage(data["initialization_message"]));

            dispatch(setCollaborationPersona(persona));
            dispatch(setAutoInteractionId(data["auto_interaction_id"]));
            dispatch(
                addAutoInteractionMessage({
                    message_format: "normal",
                    message_type: "ai",
                    message: data["initialization_message"]
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not initialize collaboration",
                description: "Please try again later",
                status: "error"
            });
        } finally {
            dispatch(setIsAutoInteractionInitializing(false));
        }
    }

    // async function initializeCollaborationApi() {
    //     try {
    //         dispatch(setIsAutoInteractionInitializing(true));
    //         const { data } = await httpClient.post(`/api/v1/auto-interaction/create-auto-interaction`, {
    //             email: (await KeycloakClient.getInstance().getAuthProfile())?.email,
    //         });
    //         if (data.success === false) {
    //             throw new Error("Cannot init");
    //         }
    //         dispatch(setSocketRoom(data.socket));
    //         dispatch(setIsAutoInteractionInitialized(true));
    //         dispatch(setAutoInteractionId(data["auto_interaction"].id));
    //     } catch (e) {
    //         console.error(e);
    //         toast({
    //             title: "Could not initialize collaboration",
    //             description: "Please try again later",
    //             status: "error",
    //         });
    //     } finally {
    //         dispatch(setIsAutoInteractionInitializing(false));
    //     }
    // }

    async function initializeCollaborationAppApi(
        interactionId: string,
        collaborationAppId: string,
        formValues: any = {}
    ) {
        try {
            // show collaboration loading
            dispatch(setIsCollaborateLoading(true));
            // Show creating app loading
            dispatch(setIsCreatingCollaborateApp(true));

            const { data } = await httpClient.post(`/api/v1/collaboration-app/initialize-cell`, {
                auto_interaction_id: interactionId,
                collaboration_app_id: collaborationAppId,
                ...formValues
            });

            if (data.success === false) {
                throw new Error("Cannot init");
            }

            // If decision wat yes, show the next UI
            dispatch(setCollaborateViewMode("initialize"));
            // clear contents
            dispatch(clearAutoInteractionMessages());

            dispatch(clearBrainActivityMessages());

            // hide collaboration loading
            dispatch(setIsCollaborateLoading(false));
            // Hide creating app loading
            dispatch(setIsCreatingCollaborateApp(false));
            // Enable input for collaboration
            dispatch(setEnableCollaborationInput(true));
            /**
             * Clear input and display goeals
             */
            // console.log(data);
            dispatch(clearAutoInteractionMessages());
            // set active collaboration app

            // Format the app within the app group and fill missing fields
            const appGroup = data["collaboration_app_group"];
            if (appGroup.collaboration_app_list[0]) {
                appGroup.collaboration_app_list[0].json_structure = appGroup.json_structure;
                appGroup.collaboration_app_list[0].description = appGroup.description;
                appGroup.collaboration_app_list[0].title = appGroup.title;
            }

            dispatch(prependCollaborationGroup(appGroup));
            dispatch(
                setSelectCollaborationApp(data.collaboration_app_group.collaboration_app_list[0])
            );
            dispatch(
                setCollaborationAppId(data.collaboration_app_group.collaboration_app_list[0].id)
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not initialize collaboration app",
                description: "Please try again later",
                status: "error"
            });
        }
    }

    async function getInitialCollaborationMessageApi() {
        try {
            const { data } = await httpClient.post(`/api/v1/collaboration-app/get-initial-message`);

            dispatch(
                addAutoInteractionMessage({
                    ...data,
                    message_type: "ai"
                })
            );
        } catch (e) {}
    }

    async function fetchCollaborationAppGroupApi(
        options: FetchParams = {},
        mode: "prepend" | "append" | "set" = "set"
    ) {
        try {
            dispatch(setIsCollabAppLoading(true));

            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-group/list-collaboration-app-group`,
                {
                    search_criteria: {
                        ...options.search_criteria,
                        status: ["initialized", "active"]
                    },
                    pagination: {
                        page_number: options?.page || 1,
                        rows_per_page: options.limit || 300
                    },
                    // populate: {
                    //     collaboration_app: true,
                    //     collaboration_app_cell: true,
                    // },
                    select: [
                        "id",
                        "title",
                        "description",
                        "status",
                        "persona",
                        "createdAt",
                        "updatedAt",
                        "type",
                        "clone_count",
                        "rigid_app_code",
                        "clone_count",
                        "activation_date",
                        "is_base",
                        "is_commercial_skill",
                        "json_structure"
                    ],
                    sort: options.sort || { createdAt: -1 },
                    case_insensitive: !!options.case_insensitive
                }
            );

            if (data["success"] === false) {
                hotToast.error("We are unable to load your collab apps at this time");
                return;
            }
            // set data in state
            switch (mode) {
                case "set":
                default:
                    dispatch(setCollaborationAppGroups(data));
                    break;
                case "prepend":
                    dispatch(prependCollaborationGroup(data));
                    break;
                case "append":
                    dispatch(appendCollaborationGroup(data));
                    break;
            }
            // set page limit
            if (typeof options.page === "number") {
                dispatch(setCollabGroupPage(options.page));
            }
            return data;
        } catch (e) {
            return [];
        } finally {
            dispatch(setIsCollabAppLoading(false));
        }
    }

    async function fetchCollaborationAppGroupForSkillsApi(payload: {
        status?: string[];
        personaId?: string;
        isBase?: string;
    }) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-group/list-collaboration-app-group`,
                {
                    search_criteria: {
                        ...(payload.personaId &&
                            payload.personaId !== "" && { persona: payload.personaId }),
                        ...(payload.isBase &&
                            (payload.isBase === "yes" || payload.isBase === "no") && {
                                is_base: payload.isBase === "yes" ? true : { "!=": true }
                            }),
                        status: payload.status || "initialized"
                    },
                    pagination: {
                        rows_per_page: 300
                    },
                    select: [
                        "id",
                        "title",
                        "description",
                        "status",
                        "persona",
                        "createdAt",
                        "updatedAt",
                        "type",
                        "clone_count",
                        "rigid_app_code",
                        "clone_count",
                        "activation_date",
                        "is_base",
                        "is_commercial_skill"
                    ]
                }
            );
            return data;
        } catch (e) {}

        toast({
            title: "There was an error getting the persona skills",
            status: "error",
            duration: 2500
        });
        return [];
    }

    async function fetchPersonaCollaborationAppGroupApi(personaId: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/persona-collaboration-app-group/list-persona-collaboration-app-group`,
                {
                    data: {
                        search_criteria: {
                            persona: personaId
                        },
                        pagination: {
                            rows_per_page: 100
                        }
                    }
                }
            );

            if (data.success) {
                return data.data;
            }
        } catch (e) {}

        toast({
            title: "There was an error getting the persona skills",
            status: "error",
            duration: 2500
        });
        return [];
    }

    async function fetchRigidSkillsTemplatesApi() {
        try {
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-group/list-rigid-skills-templates`,
                {
                    data: {
                        search_criteria: {},
                        pagination: {
                            rows_per_page: 100
                        }
                    }
                }
            );

            if (data) {
                return data;
            }
        } catch (e) {
            toast({
                title: "There was an error getting the rigid skill templates",
                status: "error",
                duration: 2500
            });
            return [];
        }
    }

    async function fetchCancellationResourceApi(payload: any) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/cancel-resource/list-resource-cancellation-record`,
                {
                    data: {
                        search_criteria: payload
                        // select: {
                        //     resource_id: true
                        // }
                    }
                }
            );

            if (data.success === false) {
                throw new Error("Failed to list cancellation resource");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function createCancellationResourceApi(resourceId: string, type: string) {
        try {
            const { data } = await httpClient.post(
                "/api/v1/cancel-resource/create-resource-cancellation-record",
                {
                    resource_id: resourceId,
                    type: type
                }
            );

            if (data.success === false) {
                throw new Error("Could not cancel this resource");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not cancel this resource"
            });
        }
    }

    async function deleteCancellationResourceApi(resourceId: string, type: string) {
        try {
            const { data } = await httpClient.post(
                "/api/v1/cancel-resource/delete-resource-cancellation-record",
                {
                    resource_id: resourceId,
                    type: type
                }
            );

            if (data.success === false) {
                throw new Error("Could not resume this resource");
            } else {
                return data;
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not resume this resource"
            });
        }
    }

    async function updatePersonaCollaborationAppGroupApi(
        personaId: string,
        peronaAppList: PersonaCollaborationAppGroupModel[]
    ) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/persona-collaboration-app-group/create-or-update-persona-collaboration-app-group`,
                {
                    persona: personaId,
                    persona_collaboration_app_group_list: peronaAppList
                }
            );

            if (data.success) {
                return data.data;
            }
        } catch (e) {}

        toast({
            title: "There was an error updating the persona skills",
            status: "error",
            duration: 2500
        });
        return null;
    }

    async function createAutoInteractionMessageApi(interactionId: string, message: string) {
        try {
            dispatch(
                addAutoInteractionMessage({
                    message,
                    message_type: "user"
                })
            );

            const { data } = await httpClient.post(
                `/api/v1/auto-interaction-message/create-auto-interaction-message`,
                {
                    auto_interaction_id: interactionId,
                    message
                }
            );

            if (data.success === false) {
                throw new Error("Cannot init");
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not send message to AI in collaboration"
            });
        }
    }

    async function sendExecutionMessageApi(collaborationAppId: string, message: string) {
        try {
            // `dispatch(addAutoInteractionMessage({
            //     message,
            //     message_type: "user"
            // }));`

            const { data } = await httpClient.post(
                `/api/v1/collaboration-app/send-execution-message`,
                {
                    collaboration_app_id: collaborationAppId,
                    message
                }
            );

            if (data.success === false) {
                throw new Error("Cannot init");
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not send message to AI in collaboration"
            });
        }
    }

    async function sendExecutionCommandApi(
        collaborationAppId: string,
        response_type:
            | "execute_next_cell"
            | "execute_previous_cell"
            | "execute_tool"
            | "cancel_execute_tool",
        humanFeedBack = ""
    ) {
        try {
            // Set loading state to true
            dispatch(setIsCollaborateLoading(true));
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app/send-execution-command`,
                {
                    collaboration_app_id: collaborationAppId,
                    ...(humanFeedBack && { human_feedback: humanFeedBack }),
                    response_type
                }
            );

            if (data.success === false) {
                throw new Error("Cannot init");
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not send message to AI in collaboration"
            });
        } finally {
            // Set loading state to false
            dispatch(setIsCollaborateLoading(false));
        }
    }

    async function proceedAutoInteractionActionApi(
        interactionId: string,
        collaborationAppId: string,
        action: string
    ) {
        try {
            const { data } = await httpClient.post(`/api/v1/collaboration-app/proceed-action`, {
                auto_interaction_id: interactionId,
                collaboration_app_id: collaborationAppId,
                action
            });

            if (data.success === false) {
                throw new Error("Cannot init");
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not proceed action"
            });
        }
    }

    async function updateInteractionGoalApi(id: string, text: string) {
        try {
            const { data } = await httpClient.post(`/api/v1/collaboration-app/update-goal`, {
                item: id,
                updated_text: text
            });

            if (data.success === false) {
                throw new Error("Cannot update goal");
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not proceed action"
            });
        }
    }

    async function executeCollaborationAppApi(collaborationAppId: string) {
        try {
            dispatch(setIsExecuting(true));
            const { data } = await httpClient.post(`/api/v1/collaboration-app/execute-app`, {
                collaboration_app_id: collaborationAppId
            });

            // Executed app
            dispatch(setIsExecuting(false));
            dispatch(setIsExecuted(true));
        } catch (e) {
            console.error(e);
            // TODO: SHOW TOAST
            // dispatch(setSelectCollaborationApp(null));
            // dispatch(setCollaborateViewMode("start"));
            toast({
                title: "We are unable to execute this app",
                description: "Please try again later",
                status: "error"
            });
            dispatch(setIsExecuting(false));
            dispatch(setIsExecuted(false));
        }
    }

    async function executeViewCollaborationAppApi(collaborationAppId: string) {
        try {
            // dispatch(setIsExecuting(true));
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app/view-collaboration-app`,
                {
                    collaboration_app_id: collaborationAppId
                }
            );

            // Executed app
            dispatch(setIsExecuting(false));
            dispatch(setIsExecuted(true));
        } catch (e) {
            console.error(e);
            // TODO: SHOW TOAST
            // dispatch(setSelectCollaborationApp(null));
            // dispatch(setCollaborateViewMode("start"));
            toast({
                title: "We are unable to execute this app",
                description: "Please try again later",
                status: "error"
            });
            dispatch(setIsExecuting(false));
            dispatch(setIsExecuted(false));
        }
    }

    async function deleteCollaborationAppApi(
        collaborationGroupId: string,
        collaborationAppId: string
    ) {
        try {
            await httpClient.post(`/api/v1/collaboration-app/delete-collaboration-app`, {
                id: collaborationAppId
            });
            dispatch(
                removeCollaborationGroupApp({
                    groupId: collaborationGroupId,
                    appId: collaborationAppId
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not delete collaboration app",
                status: "error"
            });
        }
    }

    async function updateCollaborationGroupApi(
        collaborationGroupId: string,
        payload: { title: string; description: string; goal_list: string[] }
    ) {
        try {
            await httpClient.post(
                `/api/v1/collaboration-app-group/update-collaboration-app-group`,
                {
                    id: collaborationGroupId,
                    ...payload
                }
            );
            await fetchCollaborationAppGroupApi();
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not update collaboration group",
                status: "error"
            });
        }
    }

    async function updateGlobalCollaborationGroupApi(
        argumentObject: any,
        selectedPersonas: Array<string>,
        selectedClonedSkills: Array<string>,
        replaceValue: boolean,
        rigidAppCode: string
    ) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-group/global-update-collaboration-app-group`,
                {
                    argument_object: argumentObject,
                    persona: selectedPersonas,
                    skills: selectedClonedSkills,
                    replace_value: replaceValue,
                    rigid_app_code: rigidAppCode
                }
            );

            if (data) {
                return data;
            } else {
                toast({
                    title: "Could not update all the collaboration groups",
                    status: "error"
                });
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not update all the collaboration groups",
                status: "error"
            });
        }
    }

    async function deleteCollaborationGroupApi(collaborationGroupId: string) {
        try {
            await httpClient.post(
                `/api/v1/collaboration-app-group/delete-collaboration-app-group`,
                {
                    id: collaborationGroupId
                }
            );
            dispatch(removeCollaborationGroup(collaborationGroupId));
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not delete collaboration group",
                status: "error"
            });
        }
    }

    async function cloneCollaborationAppGroupApi(
        collaborationGroupId: string,
        title: string,
        description: string
    ) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-group/clone-collaboration-app-group`,
                {
                    collaboration_app_group_id: collaborationGroupId,
                    title: title,
                    description: description
                }
            );
            dispatch(prependCollaborationGroup(data["collaboration_app_group"]));
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not clone collaboration group",
                status: "error"
            });
        }
    }

    async function cloneCollaborationAppApi(collaborationGroupId: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/collaboration-app/clone-collaboration-app`,
                {
                    id: collaborationGroupId
                }
            );
            dispatch(
                addCollaborationGroupApp({
                    groupId: collaborationGroupId,
                    app: data
                })
            );
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not clone collaboration app",
                status: "error"
            });
        }
    }

    /**
     * ============== WAITLIST SECTION ==============
     *
     */
    async function joinWaitListApi(payload) {
        return httpClient.post("/api/v1/waitlist/create-waitlist", {
            ...payload,
            platform: "neo_ai"
        });
    }

    async function getWaitListCountApi() {
        return httpClient.post("/api/v1/waitlist/get-waitlist-count", {
            platform: "neo_ai"
        });
    }

    async function joinNewsLetterApi(payload) {
        return httpClient.post("/api/v1/newsletter/create-newsletter", payload);
    }

    async function fetchChatReplyApi(payload: any) {
        try {
            dispatch(setChatLoadingStatus(true));
            // Show typing bubble
            dispatch(
                addChatQuery({
                    type: "ai",
                    isTyping: true,
                    isLoading: true,
                    message: ""
                })
            );
            // Fetch history before search
            // let searchId = searchIds.join(",");
            // const {data: historyData} = await httpClient.post(`/search/history`, addAPICredentialsToPayload({search_id: searchId}));
            // Fetch api
            const { data } = await httpClient.post<SearchModel>(`/api/v1/chat/rlhf-chat`, payload);

            dispatch(
                updateLastChatQuery({
                    id: (data.id as number).toString(),
                    isTyping: true,
                    isLoading: false,
                    message: data.reply as string,
                    type: "ai"
                })
            );
        } catch (e) {
            console.log(e);
            console.log("FAiled");
            dispatch(removeLastChatQuery());
            // remove typing bubbles
            dispatch(removeTypingQuery());
            toast({
                title: "Cannot connect to server now. Please try again later",
                status: "error"
            });
        } finally {
            dispatch(setChatLoadingStatus(false));
        }
    }

    /* Persona Section */
    async function fetchPersonaApi(
        search: any,
        params?: any,
        mode: "append" | "set" = "set",
        fetchGeneralAIEntity = false
    ) {
        let gaiResponse: any = null;
        let personaData: PersonaModel[];

        try {
            dispatch(setPersonaLoading(true));

            const personaResponse = await httpClient.post(`/api/v1/persona/list-persona`, {
                data: {
                    search_criteria: search || {},
                    pagination: {
                        rows_per_page: params?.limit || 50,
                        page_number: params?.page || 1
                    },
                    sort: params?.sort || { createdAt: -1 },
                    case_insensitive: !!params?.case_insensitive
                }
            });

            // Fetch general ai entithy
            if (fetchGeneralAIEntity) {
                gaiResponse = await httpClient.post(`/api/v1/persona/list-persona`, {
                    data: {
                        search_criteria: { is_general_entity: "yes" },
                        pagination: {
                            rows_per_page: 1,
                            page_number: 1
                        }
                    }
                });
            }

            if (personaResponse.data.success === false || gaiResponse?.data?.success === false) {
                throw new Error("Cannot load personas");
            }

            dispatch(setPersonaLoadingError(""));
            if (personaResponse && personaResponse.data) {
                // Add the data to state

                if (gaiResponse?.data) {
                    const generalAIIndex = personaResponse.data.findIndex(
                        (persona) => persona.is_general_entity === "yes"
                    );
                    if (generalAIIndex < 0) {
                        personaResponse.data.unshift(gaiResponse.data[0]);
                    }
                }

                personaData = personaResponse.data;
                // generalEntityData = personaResponse.data.filter(persona => persona.is_general_entity === "yes");
                // personaData = personaResponse.data.filter(persona => persona.is_general_entity !== "yes");

                // set personas
                // set data in state
                switch (mode) {
                    case "set":
                    default:
                        dispatch(populatePersonas(personaData));
                        break;
                    case "append":
                        dispatch(appendPersonas(personaData));
                        break;
                }
                // dispatch(populatePersonas(personaData));

                // general entities
                // dispatch(setGeneralEntities(generalEntityData));
                if (typeof params?.page === "number") {
                    dispatch(setPersonaPage(params?.page));
                }

                return personaData;
            } else {
                return [];
            }
        } catch (e) {
            console.error(e);
            dispatch(setPersonaLoadingError("Unable to fetch personas"));
            hotToast.error("Cannot fetch your personas at this time, Please try again later");
        } finally {
            dispatch(setPersonaLoading(false));
        }
    }

    async function createPersonaApi(payload: PersonaModel): Promise<PersonaModel> {
        const { data } = await httpClient.post(`/api/v1/persona/create-persona`, {
            ...payload,
            organization: activeOrganization?.id
        });

        if (data.success === false) {
            if (Array.isArray(data.error) && data.error.length > 0) {
                data.error.forEach((errorItem) => {
                    let errorMessage = "";

                    if (typeof errorItem === "string") {
                        errorMessage = errorItem;
                    } else {
                        errorMessage = Array.isArray(errorItem.message)
                            ? errorItem.message?.[0]
                            : errorItem.message;
                    }
                    hotToast.error(errorMessage);
                });
            } else if (data.error?.message) {
                hotToast.error(data.error.message);
            } else {
                hotToast.error("Could not successfully create the persona");
            }

            throw new Error("Failed to create user");
        } else {
            const record = {
                ...payload,
                id: data["persona"].id
            };

            // add to state
            dispatch(addPersona(record));

            return record;
        }
    }

    async function updatePersonaApi(payload: PersonaModel) {
        // remove problematice keys
        delete payload["writing_sampling"];
        // Call request
        const { data } = await httpClient.post(`/api/v1/persona/update-persona`, {
            ...payload,
            organization: activeOrganization?.id
        });

        if (data.success === false) {
            if (Array.isArray(data.error) && data.error.length > 0) {
                data.error.forEach((errorItem) => {
                    let errorMessage = "";

                    if (typeof errorItem === "string") {
                        errorMessage = errorItem;
                    } else {
                        errorMessage = Array.isArray(errorItem.message)
                            ? errorItem.message?.[0]
                            : errorItem.message;
                    }
                    hotToast.error(errorMessage);
                });
            } else if (data.error?.description) {
                hotToast.error(data.error.description);
            } else {
                hotToast.error("Could not successfully update the persona");
            }

            throw new Error("Failed to update persona");
        } else {
            // add to state
            dispatch(updatePersona(payload));
            return data;
        }
    }

    async function attachSkillToPersonaApi(personaId: string, collaboratorAppGroupId: string) {
        const { data } = await httpClient.post(
            "/api/v1/collaboration-app-group/attach-persona-to-collaboration-app-group",
            {
                collaboration_app_group_id: collaboratorAppGroupId,
                persona_id: personaId
            }
        );

        if (data.success === false) {
            if (Array.isArray(data.error) && data.error.length > 0) {
                data.error.forEach((errorItem) => {
                    let errorMessage = "";

                    if (typeof errorItem === "string") {
                        errorMessage = errorItem;
                    } else {
                        errorMessage = Array.isArray(errorItem.message)
                            ? errorItem.message?.[0]
                            : errorItem.message;
                    }

                    hotToast.error(errorMessage);
                });
            } else if (data.error?.description) {
                hotToast.error(data.error.description);
            } else {
                hotToast.error("Could not add persona to skill");
            }
        } else {
            return data;
        }
    }

    async function createGeneralEntityApi(payload: PersonaModel) {
        // remove problematice keys
        delete payload["writing_sampling"];

        // Call request
        const { data } = await httpClient.post(`/api/v1/persona/create-general-entity`, payload);

        if (data.success === false) {
            if (data.error?.description) {
                hotToast.error("Unable to create entity");
            }
            throw new Error("Failed to update persona");
        } else {
            payload.id = data?.persona?.id;
            // add to state
            dispatch(updatePersona(payload));
        }
    }

    async function initiateDeepenLifeApi(personaId: string) {
        // Call request
        const { data } = await httpClient.post(`/api/v1/persona/activate-persona-ambassador`, {
            persona: personaId
        });

        if (data.success) {
            dispatch(updatePersona({ id: personaId, deepen_life: "yes" }));
        } else {
            if (Array.isArray(data.error) && data.error.length > 0) {
                data.error.forEach((errorItem) => {
                    let errorMessage = "";

                    if (typeof errorItem === "string") {
                        errorMessage = errorItem;
                    } else {
                        errorMessage = Array.isArray(errorItem.message)
                            ? errorItem.message?.[0]
                            : errorItem.message;
                    }

                    hotToast.error("Unable Opt in to Deepen Life");
                });
            } else if (data.error?.description) {
                toast({
                    title: "Unable Opt in to Deepen Life",
                    description: data.error.description,
                    status: "error"
                });
            } else {
                hotToast.error("Unable Opt in to Deepen Life");
            }
        }
    }

    async function performCashOutApi(personaId: string) {
        // Call request
        try {
            const { data } = await httpClient.post(`/api/v1/persona/cash-out`, {
                persona: personaId
            });

            if (data.success) {
                toast({
                    title: "Cash Out Successful",
                    status: "success"
                });

                return true;
            } else {
                if (Array.isArray(data.error) && data.error.length > 0) {
                    data.error.forEach((errorItem) => {
                        let errorMessage = "";

                        if (typeof errorItem === "string") {
                            errorMessage = errorItem;
                        } else {
                            errorMessage = Array.isArray(errorItem.message)
                                ? errorItem.message?.[0]
                                : errorItem.message;
                        }

                        toast({
                            title: "Unable to Cash Out",
                            description: errorMessage,
                            status: "error"
                        });
                    });
                } else if (data.error?.description) {
                    toast({
                        title: "Unable to Cash Out",
                        description: data.error.description,
                        status: "error"
                    });
                } else {
                    toast({
                        title: "Unable to Cash Out",
                        status: "error"
                    });
                }

                return false;
            }
        } catch (e) {
            toast({
                title: "Unable to Cash Out",
                status: "error"
            });

            return false;
        }
    }

    async function deletePersonaApi(personaId: string) {
        const { data } = await httpClient.post(`/api/v1/persona/delete-persona`, {
            id: personaId,
            organization: activeOrganization?.id
        });

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to delete persona",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Failed to delete persona");
        } else {
            // add to state
            dispatch(removePersona(personaId));
        }
    }

    async function listPersonaMemoryApi(personaId: string) {
        try {
            const { data } = await httpClient.post(`/api/v1/persona-data-upload/list-memory`, {
                persona: personaId
            });
            // console.log(data);
            const memories = data
                .filter((d) => !!d.data_sector)
                .map((memory) => memory.data_sector);

            if (data.success === false) {
                throw new Error("Failed to create user");
            } else {
                // add to state
                dispatch(setSelectedPersonaMemories(memories));
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function listPersonaUserPerceptor(personaId: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/persona-user-perceptor/list-persona-user-perceptor`,
                {
                    data: {
                        // status: "active",
                        persona: personaId
                    }
                }
            );

            if (data.success === false) {
                throw new Error("Failed to list persona user perceptors");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function listGmailUrl() {
        try {
            const { data } = await httpClient.post(`/api/v1/user-perceptor/get-gmail-oauth-url`, {
                data: {}
            });

            if (data.success === false) {
                throw new Error("Failed to list persona user perceptors");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function listUserPerceptor(isActivator?: boolean, perceptorCode?: any) {
        try {
            const requestData: any = {
                status: "active"
            };

            if (isActivator !== undefined) {
                requestData.is_activator = isActivator;
            }

            if (perceptorCode !== undefined) {
                requestData.perceptor_code = perceptorCode;
            }

            const { data } = await httpClient.post(`/api/v1/user-perceptor/list-user-perceptor`, {
                data: requestData
            });

            if (data.success === false) {
                throw new Error("Failed to list user perceptors");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function createOrUpdateUserPerceptor(
        code: string,
        status: string,
        perceptorSettings: any
    ) {
        const { data } = await httpClient.post(
            `/api/v1/user-perceptor/create-or-update-user-perceptor`,
            {
                status: status,
                perceptor_code: code,
                perceptor_setting: perceptorSettings
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create or update the user perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function listUserApiKey() {
        try {
            const { data } = await httpClient.post(`/api/v1/user-api-key/list-user-api-key`, {
                data: {}
            });

            if (data.success === false) {
                throw new Error("Failed to list user api keys");
            } else {
                return data.data;
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function createUserApiKeyWithPersona(label: string, personas: any) {
        const { data } = await httpClient.post(
            `/api/v1/user-api-key/create-user-api-key-with-persona`,
            {
                label: label,
                persona_list: personas
            }
        );

        if (data.success === false) {
            throw new Error("Failed to create user api key");
        } else {
            data.data.user_api_key.personas = personas;
            return data.data;
        }
    }

    async function addMailgunDomainApi(label: string, persona: any) {
        const { data } = await httpClient.post(`/api/v1/mailgun/add-mailgun-domain`, {
            domain: label,
            persona: persona
        });

        if (data.success === false) {
            if (Array.isArray(data.error) && data.error.length > 0) {
                data.error.forEach((errorItem) => {
                    let errorMessage = "";

                    if (typeof errorItem.message === "string") {
                        errorMessage = errorItem.message;
                    } else {
                        errorMessage = Array.isArray(errorItem.message)
                            ? errorItem.message?.[0]
                            : errorItem.message;
                    }
                    hotToast.error(errorMessage);
                });
            } else if (data.error?.description) {
                hotToast.error(data.error.description);
            } else {
                throw new Error("Failed to add mailgun domain");
            }
        } else {
            dispatch(updatePersona({ profile_photo: data }));
            return data;
        }
    }

    async function editApiKeyWithPersona(keyId: string, label: string, personas: any) {
        const { data } = await httpClient.post(
            `/api/v1/user-api-key/edit-user-api-key-with-persona`,
            {
                id: keyId,
                label: label,
                persona_list: personas
            }
        );
        if (data.success === false) {
            throw new Error("Failed to edit user api key");
        } else {
            return data.data;
        }
    }

    async function deleteUserApiKey(keyId: string) {
        const { data } = await httpClient.post(`/api/v1/user-api-key/delete-user-api-key`, {
            id: keyId
        });

        if (data.success === false) {
            throw new Error("Failed to delete user api key");
        } else {
            return data;
        }
    }

    async function addPersonaMemoryApi(personaId: string, sectorId) {
        const { data } = await httpClient.post(`/api/v1/persona-data-upload/add-memory`, {
            persona: personaId,
            data_sector: sectorId
        });

        if (data.success === false) {
            throw new Error("Failed to create user");
        } else {
            // add to state
            // dispatch(updatePersona(payload));
        }
    }

    async function deletePersonaMemoryApi(personaId: string, memoryId: string) {
        const { data } = await httpClient.post(`/api/v1/persona-data-upload/forget-memory`, {
            persona: personaId,
            data_upload: memoryId
        });

        if (data.success === false) {
            throw new Error("Failed to create user");
        } else {
            // add to state
            // dispatch(updatePersona(payload));
        }
    }

    async function personalityWritingSamplingApi(personaId?: string, userQuery?: string) {
        const { data } = await httpClient.post(`/api/v1/writing-sampling/get-writing-sampling`, {
            persona: personaId,
            user_reply: userQuery
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function personalitySpecificKnowledgeApi(personaId?: string, userQuery?: string) {
        const { data } = await httpClient.post(
            `/api/v1/specific-knowledge/get-specific-knowledge`,
            {
                persona: personaId,
                user_reply: userQuery
            }
        );

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }
    /** start of api keys */

    async function createPersonaApiKeyApi(personaId: string, name: string) {
        const { data } = await httpClient.post(`/api/v1/persona-api-key/create-persona-api-key`, {
            persona: personaId,
            name: name
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function createPersonaUserPerceptor(
        personaId: string,
        status: string,
        userPerceptor: string,
        perceptorSettings: any
    ) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/create-persona-user-perceptor`,
            {
                persona: personaId,
                status: status,
                user_perceptor: userPerceptor,
                perceptor_setting: perceptorSettings
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create persona user perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data.data;
        }
    }

    async function createOrUpdatePersonaUserPerceptor(
        personaId: string,
        userPerceptor: string,
        index: number,
        emailConfigListItem: any,
        isMailgun?: boolean
    ) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/create-or-update-persona-user-perceptor`,
            {
                persona: personaId,
                user_perceptor: userPerceptor,
                index: index,
                email_config_list_item: emailConfigListItem,
                ...(isMailgun ? { perceptor: "mailgun" } : {})
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create or update persona user perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data.data;
        }
    }

    async function createTwilioPersonaUserPerceptorApi(
        personaId: string,
        userPerceptor: string,
        perceptorSetting: any
    ) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/create-persona-user-perceptor/twilio`,
            {
                persona: personaId,
                // status: "active",
                user_perceptor: userPerceptor,
                perceptor_setting: perceptorSetting
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create or update twilio perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data.data;
        }
    }

    async function updateTwilioPersonaUserPerceptorApi(
        id: string,
        index: number,
        perceptorSetting: any
    ) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/update-persona-user-perceptor/twilio`,
            {
                id: id,
                status: "active",
                index: index,
                perceptor_setting: perceptorSetting
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create or update twilio perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data.data;
        }
    }

    async function updatePersonaUserPerceptor(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/update-persona-user-perceptor`,
            payload
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to update persona user perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Failed to update persona user perceptor");
        } else {
            return data;
        }
    }

    async function deletePersonaUserPerceptor(perceptorId: string, index: number) {
        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/delete-persona-user-perceptor`,
            {
                id: perceptorId,
                index: index
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to delete persona user perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function getPersonaApiKeysApi(personaId?: string) {
        const { data } = await httpClient.post(`/api/v1/persona-api-key/list-persona-api-key`, {
            data: {
                search_criteria: {
                    persona: personaId
                }
            }
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function deletePersonaApiKeyApi(keyId: string) {
        const { data } = await httpClient.post(`/api/v1/persona-api-key/delete-persona-api-key`, {
            id: keyId
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    /** start of persona invites */
    async function createPersonaInvitationApi(
        personaId: string,
        email: string,
        role: string,
        organizationId: string,
        status: string = "draft"
    ) {
        const { data } = await httpClient.post(`/api/v1/persona-invite/create-persona-invite`, {
            persona: personaId,
            email: email,
            role: role,
            organization: organizationId,
            status: status
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function listPersonaInvitationApi(personaId?: string) {
        const { data } = await httpClient.post(`/api/v1/persona-invite/list-persona-invite`, {
            data: {
                search_criteria: {
                    ...(personaId && { persona: personaId })
                },
                sort: {
                    createdAt: -1
                }
            }
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function deletePersonaInvitationApi(personaId: string, email: string) {
        const { data } = await httpClient.post(`/api/v1/persona-invite/delete-persona-invite`, {
            email,
            persona: personaId
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    /** start of personality assessment */
    async function createPersonalityAssessmentApi(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/personality-assessment/create-personality-assessment`,
            payload
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create personality assessment",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Failed to create personality assessment");
        } else {
            return data;
        }
    }

    async function listPersonalityAssessmentApi(search: any, params?: any) {
        try {
            const personalityAssessmentResponse = await httpClient.post(
                `/api/v1/personality-assessment/list-personality-assessment`,
                {
                    data: {
                        search_criteria: search,
                        pagination: {
                            rows_per_page: params?.limit || 50,
                            page_number: params?.page || 1
                        },
                        sort: params?.sort || { createdAt: 1 }
                    }
                }
            );

            if (personalityAssessmentResponse && personalityAssessmentResponse.data) {
                // Add the data to state
                // dispatch(
                //     populatePersonalityAssessment(
                //         personalityAssessmentResponse.data,
                //     ),
                // );
                return personalityAssessmentResponse.data;
            } else {
                return [];
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function updatePersonalityAssessmentApi(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/personality-assessment/update-personality-assessment`,
            payload
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to update personality assessment",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Failed to update personality assessment");
        } else {
            return data;
        }
    }

    async function deletePersonalityAssessmentApi(id: string) {
        const { data } = await httpClient.post(
            `/api/v1/personality-assessment/delete-personality-assessment`,
            { id }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to delete personality assessment",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Failed to delete personality assessment");
        } else {
            return data;
        }
    }
    /**
     *  @param personaId: string
     *  @description Get persona personality trait questions
     */
    async function getPersonaPersonalityTraitQuestionsApi(personaId: string) {
        try {
            let { data } = await httpClient.post(
                    `/api/v1/personality-trait/question-personality-trait`,
                    {
                        persona: personaId
                    }
                ),
                uniqueQuestions = new Set(),
                uniqueArray: any[] = [];

            if (data.success === false) {
                // Show error from backend
                if (typeof data.error === "string") {
                    hotToast.error(data.error);
                } else if (data.error.description) {
                    hotToast.error(data.error.description);
                } else {
                    hotToast.error("Unable to get personality trait questions");
                }
            }
            // Remove duplicate questions
            for (const item of data) {
                if (!uniqueQuestions.has(item.question)) {
                    uniqueArray.push(item);
                    uniqueQuestions.add(item.question);
                }
            }

            dispatch(setPersonalityTraitQuestions(uniqueArray));
            return uniqueArray;
        } catch (e) {
            hotToast.error("Unable to get personality trait response");
            return [];
        }
    }

    async function savePersonalityTraitTestApi(questions: any[], personaId: string) {
        try {
            await httpClient.post(`/api/v1/personality-trait/submit-answer-personality-trait`, {
                question_answers: questions,
                persona: personaId
            });
            toast({
                title: "Successfully saved",
                status: "success"
            });
        } catch (e) {
            toast({
                title: "Unable to save personality trait test",
                description: "Please try again later",
                status: "error"
            });
        }
    }
    // /**
    //  * Get persona performance
    //  */
    // async function listPersonaPerformanceApi(personaId: string) {
    //     try {
    //         const { data } = await httpClient.post(`/api/v1/persona-performance/list-persona-performance`, {
    //             data: { persona: personaId },
    //         });
    //         // dispatch(setPersonaPerformance(data));
    //         return data;
    //     } catch (e) {
    //         // toast({
    //         //     title: "Unable to get persona performance data",
    //         //     description: "Please try again later",
    //         //     status: "error",
    //         // });
    //         return null;
    //     }
    // }

    /**
     * Upload persona profile photo
     */
    async function uploadPersonaProfilePhotoApi(file: any, personaId: string) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            const { data } = await httpClient.post(
                `/api/v1/persona/add-profile-picture?persona=${personaId}`,
                formData
            );

            if (data.success === false) {
                if (Array.isArray(data.error) && data.error.length > 0) {
                    data.error.forEach((errorItem) => {
                        let errorMessage = "";

                        if (typeof errorItem === "string") {
                            errorMessage = errorItem;
                        } else {
                            errorMessage = Array.isArray(errorItem.message)
                                ? errorItem.message?.[0]
                                : errorItem.message;
                        }
                        hotToast.error(errorMessage);
                    });
                } else if (data.error?.description) {
                    hotToast.error(data.error.description);
                } else {
                    throw new Error("");
                }

                return null;
            } else {
                // add to state

                dispatch(
                    updatePersona({
                        id: personaId,
                        profile_picture_full_2D:
                            getProfilePictureFileName(data.data.profile_picture_url) || ""
                    })
                );
                return data;
            }
        } catch (e) {
            toast({
                title: "Unable to upload profile photo",
                description: "Please try again later",
                status: "error"
            });
            return null;
        }
    }

    /**
     * list persona portals
     */
    async function listPersonaPortalsApi() {
        try {
            const { data } = await httpClient.post(`/api/v1/persona/list-persona-room`, {
                data: {}
            });
            return data.data;
        } catch (e) {
            toast({
                title: "Unable to get persona portals",
                description: "Please try again later",
                status: "error"
            });
            return [];
        }
    }

    /**
     *  ================= ORGANIZATION ================
     */
    async function listOrganizationApi(search = {}, params: FetchParams = {}) {
        const { data } = await httpClient.post(`/api/v1/organization/list-organization`, {
            data: {
                search_criteria: search,
                pagination: {
                    rows_per_page: params.limit || 50,
                    page_number: params.page || 1
                },
                populate: {
                    user: true
                },
                sort: {
                    createdAt: 1
                }
            }
        });
        if (data.success === false) {
            toast({
                title: "There was an error fetching your organizations, Please try again later",
                status: "error",
                duration: 2500
            });
            throw new Error("Failed to fetch organizations");
        } else {
            return data;
        }
    }

    async function createOrganizationApi(payload) {
        const { data } = await httpClient.post(`/api/v1/organization/create-organization`, payload);
        if (data.success === false) {
            if (data.error?.description) {
                hotToast.error(data.error.description);
                return;
            }
            // hotToast.error("There was an error creating your organization, Please try again later");
            // throw new Error("Failed to create organization");
        } else {
            // fetch organization members
            console.log(data);
            loadSetOrganizationAndMembersApi(accountData?.id as string, data.data.organization_id);
            return data;
        }
    }

    async function updateOrganizationApi(payload) {
        const { data } = await httpClient.post(`/api/v1/organization/update-organization`, payload);
        if (data.success === false) {
            toast({
                title: "There was an error updating your organization, Please try again later",
                status: "error",
                duration: 2500
            });
            throw new Error("Failed to update organization");
        } else {
            return data;
        }
    }

    /**
     *  ================= ORGANIZATION MEMBER ================
     */
    async function listOrganizationMemberApi(organizationId: string, params: FetchParams = {}) {
        const { data } = await httpClient.post(
            `/api/v1/organization-member/list-organization-member`,
            {
                data: {
                    search_criteria: {
                        ...params.search_criteria,
                        organization: organizationId
                    },
                    pagination: {
                        rows_per_page: params.limit || 50,
                        page_number: params.page || 1
                    },
                    sort: {
                        createdAt: 1
                    }
                }
            }
        );
        if (data.success === false) {
            hotToast.error(
                "There was an error fetching your organization members, Please try again later"
            );
            throw new Error("Failed to fetch organization members");
        } else {
            return data;
        }
    }

    async function createOrganizationMemberApi(payload) {
        const { data } = await httpClient.post(
            `/api/v1/organization-member/create-organization-member`,
            payload
        );
        if (data.success === false) {
            if (data.error?.length > 0) {
                data.error.map((error) => {
                    hotToast.error(error.message);
                });
            } else {
                hotToast.error("We cannot invite user's to your organization at this time");
            }
            // toast({
            //     title: "There was an error creating your organization member, Please try again later",
            //     status: "error",
            //     duration: 2500,
            // });
            throw new Error("Failed to create organization member");
        } else {
            return data;
        }
    }

    async function updateOrganizationMemberApi(id: string, role: string) {
        const { data } = await httpClient.post(
            `/api/v1/organization-member/update-organization-member`,
            {
                id,
                role
            }
        );
        if (data.success === false) {
            if (data.error?.length > 0) {
                data.error.map((error) => {
                    hotToast.error(error.message);
                });
            } else {
                hotToast.error("We cannot invite user's to your organization at this time");
            }
            // toast({
            //     title: "There was an error creating your organization member, Please try again later",
            //     status: "error",
            //     duration: 2500,
            // });
            throw new Error("Failed to create organization member");
        } else {
            return data;
        }
    }

    async function deleteOrganizationMemberApi(memberId: string) {
        const { data } = await httpClient.post(
            `/api/v1/organization-member/delete-organization-member`,
            {
                id: memberId
            }
        );
        if (data.success === false) {
            toast({
                title: "There was an error deleting your organization member, Please try again later",
                status: "error",
                duration: 2500
            });
            throw new Error("Failed to delete organization member");
        } else {
            return data;
        }
    }

    /**
     * ====== PAYMENT METHOD ===============
     */
    async function createPaymentMethodApi(payload) {
        const { data } = await httpClient.post(
            `/api/v1/payment-method/create-payment-method`,
            payload
        );
        if (data.success === false) {
            hotToast.error(
                "There was an error creating your payment method, Please try again later"
            );
            return null;
        } else {
            // show toast
            hotToast.success("Payment method added.");
            // add data to state
            dispatch(
                addPaymentMethodToState({
                    id: data.payment_method_id,
                    ...payload
                })
            );
            return data;
        }
    }

    async function listPaymentMethodApi(params: FetchParams = {}) {
        const { data } = await httpClient.post(`/api/v1/payment-method/list-payment-method`, {
            data: params
        });
        if (data.success === false) {
            toast({
                title: "There was an error fetching your payment methods, Please try again later",
                status: "error",
                duration: 2500
            });
            return null;
        } else {
            dispatch(setPaymentMethods(data));
            return data;
        }
    }

    async function updatePaymentMethodApi(paymentMethodId: string, payload) {
        const { data } = await httpClient.post(`/api/v1/payment-method/update-payment-method`, {
            data: {
                search_criteria: { id: paymentMethodId },
                update_params: payload
            }
        });
        if (data.success === false) {
            // toast({
            //     title: "There was an error updating your payment method, Please try again later",
            //     status: "error",
            //     duration: 2500,
            // });
            throw new Error("Failed to update payment method");
        } else {
            // update state
            dispatch(setPaymentDefaultPaymentMethod(paymentMethodId));
            return data;
        }
    }

    async function deletePaymentMethodApi(paymentMethodId: string) {
        const { data } = await httpClient.post(`/api/v1/payment-method/delete-payment-method`, {
            id: paymentMethodId
        });
        if (data.success === false) {
            throw new Error("Cannot delete payment");
        } else {
            dispatch(deletePaymentMethodFromState(paymentMethodId));
            return data;
        }
    }
    /**
     * ================ PROFILE ========
     */
    async function updatePasswordApi(payload) {
        // try {
        //     const { data } = await lootNFTClient.post(`/api/v1/user/update-password`, {
        //         data: payload,
        //         auth: {
        //             access_token: token,
        //         },
        //     });
        //     if (data.success === false) {
        //         if (Array.isArray(data.error) && data.error.length > 0) {
        //             data.error.map(error => {
        //                 if (error.code === 6601) {
        //                     hotToast.error("Your current password is wrong. Please input your correct current password.")
        //                 } else if (error.message) {
        //                     hotToast.error(error.message);
        //                 } else {
        //                     hotToast.error(error.toString());
        //                 }
        //             });
        //         } else {
        //             throw new Error("Could not update password");
        //         }
        //         return null;
        //     } else {
        //         hotToast.success("Your password was updated");
        //         return data;
        //     }
        // } catch (e) {
        //     hotToast.error("We could not update your password at this time, Please try again later");
        // }
    }

    async function updateUserProfilePictureApi(file: File) {
        const formData = new FormData();
        formData.append("file", file);

        // try {
        //     const { data } = await lootNFTClient.post(`/api/v1/user/add-profile-picture`, formData, {
        //         headers: {
        //             Authorization: `Bearer ${token}`,
        //             app_token: "",
        //             "Content-Type": "multipart/form-data",
        //         },
        //     });

        //     if (data.success === false) {
        //         throw new Error("Something went wrong please try again later");
        //     }
        //     // update user profile picture
        //     const profile_picture = data.data.profile_picture_url;
        //     dispatch(updateAuthUserProfile({ profile_picture }));
        // } catch (error) {
        //     console.log(error);
        // }
    }
    /**
     * ===================== ROOMS =========
     */
    async function listRoomsApi(params: FetchParams = {}) {
        const { data } = await httpClient.post(`/api/v1/room/list-room`, { data: params });

        if (data.success == false) {
            toast({
                title: "Cannot fetch your rooms at this time",
                status: "error",
                description: "An error occurred while fetching rooms"
            });
            return null;
        } else {
            return data;
        }
    }

    async function deleteRoomApi(id: string) {
        const { data } = await httpClient.post(`/api/v1/room/delete-room`, { id });
        // remoce id from state
        if (!data.success) {
            throw new Error("Cannot delete room");
        }
        return {};
    }

    /* End of Persona Section */

    /**
     * Tools
     */
    async function listToolsApi(params: FetchParams = {}) {
        const pagination = {
            rows_per_page: params.limit || 50,
            page_number: params.page || 1
        };

        const { data } = await httpClient.post(`/api/v1/tool/list-tool`, {
            data: {
                search_criteria: params.search_criteria || {},
                pagination,
                sort: params.sort || {}
            }
        });

        if (data.success === false) {
            toast({
                title: "There was an error fetching tools, Please try again later",
                status: "error",
                duration: 2500
            });
            return null;
        } else {
            return data;
        }
    }

    async function listUserToolsApi(params: FetchParams = {}) {
        const pagination = {
            rows_per_page: params.limit || 50,
            page_number: params.page || 1
        };

        const { data } = await httpClient.post(
            `/api/v1/user-tool-configuration/list-user-tool-configuration`,
            {
                data: {
                    search_criteria: params.search_criteria || {},
                    pagination,
                    sort: params.sort || {}
                }
            }
        );

        if (data.success === false) {
            toast({
                title: "There was an error fetching user tool configurations, Please try again later",
                status: "error",
                duration: 2500
            });
            return null;
        } else {
            return data.data;
        }
    }

    async function updateUserToolsApi(userToolList) {
        const { data } = await httpClient.post(
            `/api/v1/user-tool-configuration/create-or-update-user-tool-configuration`,
            {
                tool_config_list: userToolList
            }
        );

        if (data.success === false) {
            toast({
                title: "There was an error updating user tool configurations, Please try again later",
                status: "error",
                duration: 2500
            });

            return false;
        }

        return true;
    }
    /* End of Tools Section */

    /**
     * List subscriptions prices
     */
    async function listSubscriptionPricesApi() {
        const { data } = await httpClient.post(
            `/api/v1/subscription-price/list-subscription-price`,
            {
                data: {
                    search_criteria: {
                        product: process.env.REACT_APP_STRIPE_PRODUCT_ID as string
                    }
                }
            }
        );
        if (data.success === false) {
            toast({
                title: "There was an error fetching the subscription prices, Please try again later",
                status: "error",
                duration: 2500
            });
            return null;
        } else {
            return data.data;
        }
    }
    /**
     * Create stripe checkout session
     */
    async function createSubscriptionSessionApi(payload: any) {
        const success_url = `${process.env.REACT_APP_URL}/payments/callback/subscription-success`,
            cancel_url = `${process.env.REACT_APP_URL}/payments/callback/subscription-cancelled`;
        try {
            const { data } = await httpClient.post(
                `/api/v1/subscription-session/create-subscription-session`,
                {
                    data: {
                        ...payload,
                        success_url: success_url,
                        cancel_url: cancel_url
                    }
                }
            );

            if (data.success === false) {
                toast({
                    title: "There was an error creating the subscription session, Please try again later",
                    status: "error",
                    duration: 2500
                });
                throw new Error("Cannot create subscription session");
            } else {
                return data;
            }
        } catch (e) {
            toast({
                title: "There was an error creating the subscription session, Please try again later",
                status: "error",
                duration: 2500
            });
            return null;
        }
    }

    /**
     * list user organization
     */
    async function listUserOrganizationApi(search: any = {}, params: FetchParams = {}) {
        let organizations: OrganizationModel[] = [];
        try {
            const { data } = await httpClient.post(`/api/v1/organization/list-user-organization`, {
                data: {
                    search_criteria: search,
                    pagination: {
                        rows_per_page: params.limit || 50,
                        page_number: params.page || 1
                    },
                    populate: {
                        user: true
                    },
                    sort: {
                        createdAt: 1
                    }
                }
            });

            if (data.success === false) {
                throw new Error("Cannot fetch organizations");
            } else {
                organizations = data;
            }
        } catch (e) {
            hotToast.error("We are unable to fetch your organizations at this time.");
        }
        // set it in state
        dispatch(setAccountOrganizations(organizations));
        return organizations;
    }

    // Create a cache to store ongoing requests
    //     const requestCache = new Map();
    //
    //     // Request interceptor to prevent duplicate requests
    //     httpClient.interceptors.request.use(config => {
    //         const requestId = JSON.stringify(config);
    //
    //         if (requestCache.has(requestId)) {
    //             // A request with the same configuration is already pending
    //             return Promise.reject(new Error("DUPLICATE_REQUEST"));
    //         }
    //
    //         requestCache.set(requestId, true);
    //         return config;
    //     }, error => Promise.reject(error));
    //
    // // Response interceptor to remove request from cache
    //     httpClient.interceptors.response.use(response => {
    //         const requestId = JSON.stringify(response.config);
    //         requestCache.delete(requestId);
    //         return response;
    //     }, error => {
    //         const requestId = JSON.stringify(error.config);
    //         requestCache.delete(requestId);
    //         return Promise.reject(error);
    //     });

    async function getPersonaHealthPointApi(personaId: string) {
        try {
            // const { data } = await lootNFTClient.post(`/api/v1/ambassador/get-health-by-entity-id`, {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         external_entity_id: personaId,
            //     },
            // });

            // if (data.success) {
            //     return data.data;
            // }
            return {
                health_points: 100
            };
        } catch (e) {}

        // toast({
        //     title: "There was an error getting the health points.",
        //     status: "error",
        //     duration: 2500,
        // });
        return null;
    }

    async function getPersonaTransactionApi(
        personaId: string,
        extraSearchCriteria?: Object,
        isOwnTransaction?: boolean
    ) {
        try {
            // const { data } = await lootNFTClient.post(`/api/v1/transaction/list-external-entity-transaction`, {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         external_entity_id: personaId,
            //         ...(isOwnTransaction && { is_own_transaction: isOwnTransaction }),
            //         search_criteria: {
            //             ...extraSearchCriteria,
            //             createdAt: {
            //                 ">=": dayjs().startOf("day").subtract(6, "days").valueOf(),
            //             },
            //         },
            //         sort: {
            //             createdAt: -1,
            //         },
            //     },
            // });

            // if (data.success) {
            //     return data.data;
            // }
            return [];
        } catch (e) {
            hotToast.error("Cannot get the list of your persona transactions");
        }

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return [];
    }

    /**
     * Subscripts
     */
    async function listUserSubscriptionApi() {
        try {
            dispatch(setSubscriptionStatus("loading"));
            const { data } = await httpClient.post(`/api/v1/subscription/list-subscription`, {
                data: {
                    search_criteria: {
                        status: "active",
                        product: process.env.REACT_APP_STRIPE_PRODUCT_ID as string
                    }
                }
            });

            if (data.success === false) {
                hotToast.error(data?.error?.[0]?.message);
                dispatch(setSubscriptionStatus("fetched_error"));
                return;
            }
            // add subscription data to state
            // console.log(data)
            dispatch(setAccountSubscriptionData(data[0]));
            dispatch(setSubscriptionStatus("fetched"));
        } catch (e) {
            hotToast.error("Cannot fetch your subscription data at this time, Please try again");
            dispatch(setSubscriptionStatus("fetched_error"));
        }
    }

    async function listUserSubscriptionHistoryApi(subscriptionId?: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/subscription/list-subscription-history`,
                {
                    data: {
                        search_criteria: {
                            ...(subscriptionId && { subscription: subscriptionId }),
                            product: process.env.REACT_APP_STRIPE_PRODUCT_ID as string
                        }
                    }
                }
            );

            if (data.success === false) {
                hotToast.error(data?.error?.[0]?.message);
                return [];
            }
            // add subscription data to state
            // console.log(data)
            return data;
        } catch (e) {
            hotToast.error("Cannot fetch your subscription data at this time, Please try again");
            return [];
        }
    }

    async function cancelUserSubscriptionApi(id: string) {
        const { data } = await httpClient.post(`/api/v1/subscription/cancel-subscription`, {
            data: {
                id
            }
        });
        if (data.success === false) {
            // hotToast.error(data?.error?.[0]?.message);
            throw new Error(data?.error?.[0]?.message);
        }
        // clear subscription data from state
        dispatch(clearSubscriptionData());
    }

    async function getPersonaBalanceApi(personaId?: string) {
        try {
            // const { data } = await lootNFTClient.post("/api/v1/user/get-entity-balance", {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         external_entity_id: personaId,
            //     },
            // });
            // if (data.success === false) {
            //     throw new Error("Cannot get persona balance");
            // }
            // if (data.success) {
            //     return data.data;
            // }
        } catch (e) {
            hotToast.error("Cannot get the persona's balance");
        }
        return {
            credit_balance: 0,
            ltt_balance: 0,
            arin_system_credit_balance: 0
        };

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return {};
    }

    async function donateTransferFundExternalEntityApi({ persona, amount, currency }) {
        try {
            // const { data } = await lootNFTClient.post("/api/v1/transaction/transfer-fund-to-external-entity", {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         persona,
            //         amount: amount,
            //         currency: currency
            //     },
            // });

            // if (data.success === false) {
            //     throw new Error("Cannot donate funds")
            // }

            // if (data.success) {
            //     return data.data;
            // }
            return {};
        } catch (e) {
            hotToast.error("Cannot get the list of your persona transactions");
        }

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return [];
    }

    async function donatePersonaHealthPointsApi({ persona, amount }) {
        try {
            // const { data } = await lootNFTClient.post("/api/v1/transaction/transfer-health-to-external-entity", {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         persona,
            //         amount: amount,
            //     },
            // });

            // if (data.success === false) {
            //     throw new Error("Cannot donate health")
            // }

            // if (data.success) {
            //     return data.data;
            // }
            return {};
        } catch (e) {
            hotToast.error("Cannot get the list of your persona transactions");
        }

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return [];
    }

    /**
     *
     * @param userId {string} The active user's id
     * @param organizationId {organizationId} The organization Id
     * @returns {OrganizationModel[]}
     * @description
     * This function fetches the organization data of the user then populates it in the state;
     * - First it fetches organization the user owns that corresponds to the activeUser Id saved in local storage, if
     *   the no local storage exist, the fist return result is assumed as the active organization.
     * - Second if the user owns organizations or no organization corresponding o the active, Id, it attempts to fetch organization the user is part of
     */
    async function loadSetOrganizationAndMembersApi(userId: string, organizationId?: string) {
        let activeOrganizationId = organizationId,
            activeOrganization: any = null,
            response: any[] = [],
            userMember: any = null,
            members = [];

        if (!activeOrganizationId) {
            // get active organization from localstorate
            activeOrganizationId = window.sessionStorage.getItem("active_org") as string;
        }

        try {
            response = await listOrganizationApi({
                user: userId,
                ...(activeOrganizationId && { id: activeOrganizationId })
            });

            if (response.length > 0) {
                activeOrganization = { ...response[0] };
                // set the current user as the admin of app
                dispatch(setUserRole("admin"));
                // list active organization members if the user owns the organization
                members = await listOrganizationMemberApi(activeOrganization.id);
                // console.log(activeOrganization)
                (activeOrganization as OrganizationState).members = members;
            } else {
                // fetch user organizations
                response = await listUserOrganizationApi({
                    ...(activeOrganizationId && { id: activeOrganizationId })
                });

                if (response.length > 0) {
                    activeOrganization = { ...response[0] };
                    // list active organization members if the user owns the organization
                    members = await listOrganizationMemberApi(activeOrganization.id);
                    // Get role of the user
                    userMember = members.find(
                        (member: any) =>
                            member.user?.id === userId &&
                            member.organization === activeOrganization.id
                    );
                    // The current user member's record exist
                    if (userMember) {
                        dispatch(setUserRole(userMember.role));
                    }
                    // console.log(activeOrganization)
                    (activeOrganization as OrganizationState).members = members;
                }
            }

            dispatch(setOrganizations(response));
            dispatch(setActiveOrganization(activeOrganization));
            return { organizations: response, activeOrganization };
        } catch (e) {
            console.log(e);
            hotToast.error("Cannot load organizations and members");
            return { organizations: null, activeOrganization: null };
        }
    }

    async function createPaymentChargeApi(payload, payment_motive) {
        const { data } = await httpClient.post(`/api/v1/payment-charge/create-payment-charge`, {
            data: {
                ...payload,
                payment_motive
            }
        });

        if (data.success === false) {
            throw new Error(data?.error?.[0]?.message);
        }
        /**
         * Fetch new credit balance
         * !import note: do no await this promise because we do not want it to interrupt the data being return
         * incase it fails. the data return does not depend on the promise getting resolved.
         * And don not worry about handle errors from the promise, it is already handle in the function definition.
         * This is fetch to display the new credit balance in the UI
         */
        return data;
    }

    async function listUserTransactionsApi() {
        try {
            // const { data } = await lootNFTClient.post(`/api/v1/transaction/list-user-transaction`, {
            //     data: {
            //         search_criteria: {
            //             transaction_type: ["increase_word_credit_balance", "increase_forwardable_word_credit_balance"],
            //         },
            //     },
            //     auth: {
            //         access_token: token,
            //     },
            // });

            // if (data.success === false) {
            //     hotToast.error("Cannot display transactions. Please try again later.");
            //     return [];
            // }
            // // update user profile picture
            // return data.data;
            return [];
        } catch (error) {
            hotToast.error("Cannot display transactions. Please try again later.");
            return [];
        }
    }

    async function listCreditUsageApi(user: string[], personaId?: string) {
        try {
            // const { data } = await lootNFTClient.post(`/api/v1/transaction/list-user-transaction`, {
            //     auth: {
            //         access_token: token,
            //     },
            //     data: {
            //         ...(personaId && { external_entity_id: personaId }),
            //         user,
            //         search_criteria: {
            //             transaction_type: "decrease_word_credit_balance",
            //             createdAt: {
            //                 ">=": dayjs().startOf("day").subtract(6, "days").valueOf(),
            //             },
            //         },
            //         sort: {
            //             createdAt: -1,
            //         },
            //     },
            // });

            // if (data.success) {
            //     return data.data;
            // }
            return [];
        } catch (e) {}

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return [];
    }

    async function uploadFile(file) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const { data } = await httpClient.post(`/api/v1/file/upload-file`, formData, {});

            if (data.success === false) {
                hotToast.error("Error Uploading file. Please try again.");
                return [];
            }
            // update user profile picture
            return data.data;
        } catch (error) {
            hotToast.error("Error Uploading file. Please try again.");
            return [];
        }
    }

    async function executeToolWithInputsApi(userInputs) {
        try {
            // show collaboration loading
            dispatch(setIsCollaborateLoading(true));

            const { data } = await httpClient.post(
                `/api/v1/collaboration-app-cell/execute-tool-with-user-inputs`,
                {
                    data: {
                        ...userInputs
                    }
                }
            );

            if (data.success === false) {
                hotToast.error("Error Submitting response. Please try again.");
                return [];
            }
            // update user profile picture
            return data;
        } catch (error) {
            hotToast.error("Error Submitting response. Please try again.");
            return [];
        }
    }

    async function createMailGunPerceptorApi(apiKey: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/user-perceptor/create-user-perceptor/mailgun`,
                {
                    // status: "active",
                    mailgun_api_key: apiKey
                }
            );

            if (data.success === false) {
                hotToast.error("Error saving your perceptor settings. Please try again.");
                return [];
            }
            // update user profile picture
            return data;
        } catch (error) {
            hotToast.error("Error saving your perceptor settings.. Please try again.");
            return [];
        }
    }

    async function updateMailGunPerceptorApi(apiKey: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/user-perceptor/update-user-perceptor/mailgun`,
                {
                    mailgun_api_key: apiKey
                }
            );

            if (data.success === false) {
                hotToast.error("Error saving your perceptor settings. Please try again.");
                return [];
            }
            // update user profile picture
            return data;
        } catch (error) {
            hotToast.error("Error saving your perceptor settings.. Please try again.");
            return [];
        }
    }

    async function updateTwilioPerceptorApi(accountSid: string, authToken: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/user-perceptor/update-user-perceptor/twilio`,
                {
                    account_sid: accountSid,
                    auth_token: authToken
                }
            );

            if (data.success === false) {
                hotToast.error("Error saving your perceptor settings. Please try again.");
                return [];
            }

            return data;
        } catch (error) {
            hotToast.error("Error saving your perceptor settings.. Please try again.");
            return [];
        }
    }

    async function createTwilioPerceptorApi(accountSid: string, authToken: string) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/user-perceptor/create-user-perceptor/twilio`,
                {
                    // status: "active",
                    account_sid: accountSid,
                    auth_token: authToken
                }
            );

            if (data.success === false) {
                hotToast.error("Error saving your perceptor settings. Please try again.");
                return [];
            }

            return data;
        } catch (error) {
            hotToast.error("Error saving your perceptor settings.. Please try again.");
            return [];
        }
    }

    async function replyUnreadEmailApi(persona: any, label: string) {
        const timeLimit = label ? parseInt(label) : 0;

        const { data } = await httpClient.post(
            `/api/v1/persona-user-perceptor/reply-unread-emails`,
            {
                persona_list: persona,
                time_limit: timeLimit
            }
        );

        if (data.success === false) {
            if (data.error?.description) {
                toast({
                    title: "Unable to create or update twilio perceptor",
                    description: data.error.description,
                    status: "error"
                });
            }
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    async function getVendorPublicDataApi(id: string) {
        const { data } = await userApiGatewayClient.get(`/api/v1/vendors/public?id=${id}`);
        dispatch(setVendorProfile(data));
        return data;
    }

    // Add a request interceptor
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            switch (error.status) {
                case 401:
                    // TODO: SHOW THE USER AN UNAUTHORIZED MODAL
                    toast({
                        title: "Access denied!",
                        status: "warning",
                        description:
                            "You were not authorized to carry out this request, Please refresh the page",
                        duration: 3000
                    });
                    // setTimeout(() => location.reload(), 3000);
                    break;
                case 403:
                    // TODO: SHOW THE USER A ACCESS DENIED MODAL
                    break;
                case 410:
                    // TODO LOG OUT THE USER AND SHOW A DISABLE MESSAGE
                    break;
            }
            throw error;
        }
    );

    httpClient.interceptors.request.use(async (config) => {
        config.headers.Authorization = `Bearer ${await KeycloakClient.getInstance().getAuthUserToken()}`;
        const activeOrg = window.sessionStorage.getItem("active_org");
        if (activeOrg) {
            config.headers["Neo-Vendor-Id"] = activeOrg;
        }
        return config;
    });

    userApiGatewayClient.interceptors.request.use(async (config) => {
        config.headers.Authorization = `Bearer ${await KeycloakClient.getInstance().getAuthUserToken()}`;
        const activeOrg = window.sessionStorage.getItem("active_org");
        if (activeOrg) {
            config.headers["Neo-Vendor-Id"] = activeOrg;
        }
        return config;
    });

    return {
        httpClient,
        createTwilioPerceptorApi,
        updateTwilioPerceptorApi,
        fetchSearchByIdApi,
        uploadFile,
        fetchNameSpacesV2Api,
        fetchSearchesApi,
        fetchSearchReplyApi,
        fetchNotesReplyApi,
        fetchNotesReplyInFolderApi,
        fetchNotesInThreadApi,
        fetchSearchReplyInFolderApi,
        fetchThreadReplyApi,
        downloadFileApi,
        fetchNameSpacesApi,
        fetchMemoriesApi,
        deleteAllMemoriesApi,
        getAgentNamespace,
        updateSectorApi,
        updateLocationApi,
        deleteSectorApi,
        deleteLocationApi,
        updateSectorFileNameApi,
        deleteSectorFileNameApi,
        fetchFoldersApi,
        fetchFolderByName,
        fetchCharacterApi,
        fetchHistoryApi,
        fetchThreadHistoryApi,
        changeResponseSentimentApi,
        approveSearchForTrainingApi,
        changeThreadSentimentApi,
        editSearchResponseApi,
        editThreadResponseApi,
        saveToFolderApi,
        getStructuredDataRelationshipsApi,
        uploadMemoryFileApi,
        uploadMemoryURLApi,
        updateUploadedFileDescriptionApi,
        createUserApi,
        updateUserApi,
        listUsersApi,
        // getAuthUserDataApi,
        fetchUserCredentialsApi,
        fetchPromptsApi,
        fetchPromptCategoriesApi,
        // initializeCollaborationApi,
        initializeCollaborationAppApi,
        getInitialCollaborationMessageApi,
        fetchCollaborationAppGroupApi,
        createAutoInteractionMessageApi,
        sendExecutionMessageApi,
        proceedAutoInteractionActionApi,
        updateInteractionGoalApi,
        initializeCollaborationCommunicationApi,
        executeCollaborationAppApi,
        executeViewCollaborationAppApi,
        deleteCollaborationGroupApi,
        deleteCollaborationAppApi,
        updateCollaborationGroupApi,
        cloneCollaborationAppGroupApi,
        cloneCollaborationAppApi,
        joinNewsLetterApi,
        fetchChatReplyApi,
        joinWaitListApi,
        getWaitListCountApi,
        fetchPersonaApi,
        createPersonaApi,
        updatePersonaApi,
        deletePersonaApi,
        listPersonaMemoryApi,
        listUserPerceptor,
        createUserApiKeyWithPersona,
        addMailgunDomainApi,
        editApiKeyWithPersona,
        listUserApiKey,
        deleteUserApiKey,
        createOrUpdateUserPerceptor,
        listPersonaUserPerceptor,
        addPersonaMemoryApi,
        deletePersonaMemoryApi,
        createPersonalityAssessmentApi,
        listPersonalityAssessmentApi,
        updatePersonalityAssessmentApi,
        deletePersonalityAssessmentApi,
        getPersonaPersonalityTraitQuestionsApi,
        savePersonalityTraitTestApi,
        personalitySpecificKnowledgeApi,
        personalityWritingSamplingApi,
        createPersonaApiKeyApi,
        createPersonaUserPerceptor,
        createOrUpdatePersonaUserPerceptor,
        listGmailUrl,
        updatePersonaUserPerceptor,
        deletePersonaUserPerceptor,
        getPersonaApiKeysApi,
        deletePersonaApiKeyApi,
        createPersonaInvitationApi,
        listPersonaInvitationApi,
        deletePersonaInvitationApi,
        // listPersonaPerformanceApi,
        uploadPersonaProfilePhotoApi,
        listPersonaPortalsApi,
        listOrganizationApi,
        createOrganizationApi,
        updateOrganizationApi,
        listOrganizationMemberApi,
        deleteOrganizationMemberApi,
        updateOrganizationMemberApi,
        createOrganizationMemberApi,
        createPaymentMethodApi,
        listPaymentMethodApi,
        updatePaymentMethodApi,
        deletePaymentMethodApi,
        updatePasswordApi,
        updateUserProfilePictureApi,
        listRoomsApi,
        deleteRoomApi,
        listSubscriptionPricesApi,
        createSubscriptionSessionApi,
        listToolsApi,
        listUserToolsApi,
        updateUserToolsApi,
        sendExecutionCommandApi,
        listUserOrganizationApi,
        fetchCollaborationAppGroupForSkillsApi,
        getPersonaHealthPointApi,
        fetchPersonaCollaborationAppGroupApi,
        updatePersonaCollaborationAppGroupApi,
        getPersonaTransactionApi,
        listUserSubscriptionApi,
        listUserSubscriptionHistoryApi,
        cancelUserSubscriptionApi,
        initiateDeepenLifeApi,
        getPersonaBalanceApi,
        donateTransferFundExternalEntityApi,
        donatePersonaHealthPointsApi,
        performCashOutApi,
        createGeneralEntityApi,
        loadSetOrganizationAndMembersApi,
        createPaymentChargeApi,
        listUserTransactionsApi,
        listCreditUsageApi,
        executeToolWithInputsApi,
        createMailGunPerceptorApi,
        updateMailGunPerceptorApi,
        createTwilioPersonaUserPerceptorApi,
        updateTwilioPersonaUserPerceptorApi,
        replyUnreadEmailApi,
        // User api gateway
        getVendorPublicDataApi,
        attachSkillToPersonaApi,
        fetchCancellationResourceApi,
        createCancellationResourceApi,
        deleteCancellationResourceApi,
        fetchRigidSkillsTemplatesApi,
        updateGlobalCollaborationGroupApi
    };
}

export function convertFiltersToSearchCriteria(filters) {
    return filters.reduce((acc, current) => {
        acc[current.filter] = { contains: current.value };
        return acc;
    }, {});
}
